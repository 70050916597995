import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby"
import styles from "./PixelyImage.module.css"
import Content from "./Content"
import VisibilitySensor from 'react-visibility-sensor';
import Img from "gatsby-image"
import Pixelify from "./Pixelify";

const PixelyImage = ({ fluidImg, publicImg, index, color }) => {
  const [pixelSize, setPixelSize] = useState(40);
  const [pixelVisible, setPixelVisible] = useState(true);
  const onChange = (isVisible) => {
    if (isVisible) {
      setTimeout(() => {
        setPixelSize(20);
      }, 200);
      setTimeout(() => {
        setPixelSize(10);
      }, 400);
      setTimeout(() => {
        setPixelVisible(false);
      }, 600);
      setTimeout(() => {
        setPixelVisible(false);
      }, 800);
    }
  }

  return (
    <div className={styles.container}>
      <VisibilitySensor onChange={onChange} partialVisibility={true}>
        <>
          <div style={{ opacity: pixelVisible ? 1 : 0 }} className={styles.pixelWrap}>
            <Pixelify src={publicImg} pixelSize={pixelSize}/>
          </div>
          <Img fluid={fluidImg} />
        </>
      </VisibilitySensor>
    </div>
  );
}

export default PixelyImage;
