import React, { useEffect, useState, useRef } from 'react';
import { useStaticQuery, graphql, Link } from "gatsby"
import { Helmet } from "react-helmet";
import Img from "gatsby-image"
import _ from 'lodash';
import './MarioGame.css';
import GameArrow from './GameArrow';
import Arrow from '../base/Arrow';
import Controller from './Controller';
import game from './js/mariogame';
import Logo from '../logo';
import Cookies from 'js-cookie'

const SCALE = 2.5; // How much the game is scaled by

function MarioGame({ showFullMenu, hideFullMenu, projects }) {

  const [leftSelected, setLeftSelected] = useState(false);
  const [xOffset, setXOffset] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [jumpSelected, setJumpSelected] = useState(false);
  const [rightSelected, setRightSelected] = useState(false);
  const [selectedProjectIds, setSelectedProjectIds] = useState({});
  const xPos = useRef(0);
  const gameObj = useRef(null);
  const paused = useRef(false);
  const canUnpause = useRef(false);
  const prevOffset = useRef(0);
  const bitLogo = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "lw-glitch.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const onButton = (direction, isDown, isKey) => {
    if (direction == 'LEFT') {
      setLeftSelected(isDown)
    }
    if (direction == 'RIGHT') {
      setRightSelected(isDown)
    }
    if (direction == 'JUMP') {
      setJumpSelected(isDown)
    }
    if (gameObj && !isKey) {
      gameObj.current.setButtonPress(direction, isDown);
    }
  }

  const setOnScrollX = (scroll) => {
    setXOffset(scroll);
  }

  const onMove = (x) => {
    xPos.current = x;
    const offset = Math.min(0, 80 - x);
    const cutoff = -300;
    debouncedOffset(offset);
    // Disable menu change
    // if (offset < cutoff && prevOffset.current >= cutoff) {
    //   showFullMenu();
    // }
    // if (offset >= cutoff && prevOffset.current < cutoff ) {
    //   hideFullMenu();
    // }
    prevOffset.current = offset;
  }

  const debouncedOffset = _.throttle(setOnScrollX, 2);
  useEffect(() => {
    if (gameObj.current == null) {
      let initXPos = Cookies.get('lwXPos');
      // let selectedProjectIds = {};
      let cookie = Cookies.get('lwSelectedProj');
      let selectedProjectIds = JSON.parse(cookie || "{}");
      setSelectedProjectIds(selectedProjectIds);
      Cookies.remove('lwSelectedProj');
      Cookies.remove('lwXPos');
      onMove(initXPos);
      gameObj.current = new game({
        _initX: initXPos,
        _reactOnBonk: (projectId) => {
          setSelectedProjectIds(current => {
            current[projectId] = true
            Cookies.set('lwSelectedProj', JSON.stringify(current))
            return current;
          });
          Cookies.set('lwXPos', xPos.current);
          // Two seconds to prevent key unpause
        },
        _reactOnKey: (direction, isDown) => {
          if (isDown && paused && canUnpause.current) {
            gameObj.current.resume();
            paused.current = false;
            canUnpause.current = false;
            // setSelectedProjectId(null);
          }
          onButton(direction, isDown, true);
        },
        _onClick: (e) => {
          // alert(JSON.stringify(e));
        },
        _reactOnMove: (x) => {
          onMove(x);
        }
      });
    }
//     if (window.Mario === undefined) {
//       window.Mario = {};
//     }
//     window.Mario.onBunk = onBunk;
//
    document.onkeydown = function(e) {
    switch (e.keyCode) {
      case 40:
//         e.preventDefault();
        break;
      default:
        break;
     }
  };
  return () => {
    if (gameObj.current) {
      gameObj.current.removeListeners();
      gameObj.current.clearAnimationLoop();
    }
  }
  }, []);

  return (
    <div className="MarioGame-outermost" aria-hidden="true">
    <div className="MarioGame-outerwrap">
      <div id="marioHolder" style={{ width: '100%', height: 600 }}></div>
      <div className="MarioGame-marioOverlay">
        <div className="MarioGame-marioOverlay-track" style={{ transform: `translateX(${Math.max(xOffset * 2.5, -189.5 * 16 * 2.5)}px)` }}>
          <div className="MarioGame-marioOverlay-track-inner">
            <div className="MarioGame-introOverlay">
              <div className="MarioGame-big-logo-holder">
                <Img className="MarioGame-logo" fluid={bitLogo.logo.childImageSharp.fluid} />
              </div>
              <div className="MarioGame-subtitle">A digital product workshop</div>
            </div>
            <div
              className="MarioGame-introOverlay MarioGame-endOverlay"
              style={{ left: ((190 * 16) * SCALE) }}
            >
              <div className="MarioGame-big-logo-holder">
                <div className="MarioGame-subtitle" style={{ color: '#fff' }}>
                  Let's create something cool
                </div>
                <Link to="/collaborate">
                  <div className="MarioGame-work-button">
                      Work Together
                  </div>
                </Link>
              </div>
            </div>
            {
              _.map(projects, (val, key) => {
                const proj = projects[key];
                return (
                  <div className={`${'MarioGame-projectOverlay'} ${selectedProjectIds[key] && 'MarioGame-projectOverlay-visible'}`}
                       key={key}
                       style={{ left: ((proj.position * 16) * SCALE), transform: "translateX(-25%)" }}
                       onClick={() => {
                         Cookies.set('lwSelectedProj', JSON.stringify(selectedProjectIds))
                       }}
                 >
                    {selectedProjectIds[key] && proj.component}
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
      <div id="marioCharacterHolder"></div>
      <div id="marioClickOverlay" className="MarioGame-marioOverlay" style={{ zIndex: 4, opacity: 0 }}>
        <div className="MarioGame-marioOverlay-track" style={{ transform: `translateX(${xOffset * 2.5}px)` }}>
          <div className="MarioGame-marioOverlay-track-inner">
            <div className="MarioGame-introOverlay">
              <div className="MarioGame-big-logo-holder">
                <Img className="MarioGame-logo" fluid={bitLogo.logo.childImageSharp.fluid} />
              </div>
              <div className="MarioGame-subtitle">A digital product workshop</div>
            </div>
            <div
              className="MarioGame-introOverlay MarioGame-endOverlay"
              style={{ left: ((190 * 16) * SCALE) }}
            >
              <div className="MarioGame-big-logo-holder">
                <div className="MarioGame-subtitle" style={{ color: '#fff' }}>
                  Let's create something cool
                </div>
                <Link to="/collaborate">
                  <div className="MarioGame-work-button">
                      Work Together
                  </div>
                </Link>
              </div>
            </div>
            {
              _.map(projects, (val, key) => {
                const proj = projects[key];
                return (
                  <div
                      key={key}
                      className={`${'MarioGame-projectOverlay'} ${selectedProjectIds[key] && 'MarioGame-projectOverlay-visible'}`}
                      style={{ left: ((proj.position * 16) * SCALE), transform: "translateX(-25%)" }}>
                    {selectedProjectIds[key] && proj.component}
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </div>
    <div className="MarioGame-controls">
      <div className="MarioGame-arrow-wrapper MarioGame-top-arrow-wrapper">
        <Controller
          leftSelected={leftSelected}
          rightSelected={rightSelected}
          jumpSelected={jumpSelected}
          onButton={(direction, isDown, isKey) => onButton(direction, isDown, isKey)}
         />
         <div className="MarioGame-desktopDirections MarioGame-arrow-directions-top">Use arrow keys to explore.</div>
      </div>
      <div className="MarioGame-arrow-wrapper">
        <Arrow color="#fff" direction="DOWN" />
       <div className="MarioGame-desktopDirections">Scroll down for more.</div>
     </div>
    </div>
    </div>
  );
}

export default MarioGame;
