import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import styles from "./ProjectWrapper.module.css"
import PixelyImage from '../content/PixelyImage';
import Button from '../base/Button';
import Arrow from '../base/Arrow';
const ProjectWrapper = ({
  name,
  id,
  description,
  fluidImg,
  publicImg,
  foregroundColor,
  darkBorder,
  lightBorder,
  backgroundColor,
  reverse,
  inGame
}) => {

  const [contentIndex, setContentIndex] = useState(0);
  return (
    <Link
      className={styles.containerLink}
      to={`/products/${id}`}
      state={{ fromList: true }}
    >
      <div className={`${styles.container} ${inGame && styles.inGame}`} style={{
        backgroundColor
      }}>
        <div className={styles.innerContainer}>
          <div className={styles.upperSection} style={reverse ? { flexDirection: 'row-reverse' } : {}}>
          <div className={styles.textWrapper}>
            <div className={styles.name}>
              {name}
              <div className={`${styles.arrow} ${styles.arrowMobile}`}>
                <div>
                  <div style={{ display: "flex" }}>
                    <Arrow color={"#000"} direction="RIGHT"/>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.description}>{description}</div>
            <div className={`${styles.arrow} ${styles.arrowDesktop}`}>
              <div>
                <div style={{ display: "flex" }}>
                  <Arrow color={"#000"} direction="RIGHT"/>
                </div>
              </div>
            </div>
          </div>
            <div className={styles.contentWrapper} style={{
              background: backgroundColor,
              borderBottomColor: darkBorder,
              borderRightColor: darkBorder,
              borderTopColor: lightBorder,
              borderLeftColor: lightBorder,
            }}>
              <PixelyImage fluidImg={fluidImg} publicImg={publicImg} />
            </div>

          </div>
        </div>
      </div>
    </Link>
  );
}

export default ProjectWrapper;
