import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styles from "./Controller.module.css"
import GameArrow from './GameArrow';

const Controller = ({ leftSelected, jumpSelected, rightSelected, onButton }) => {
  return (
    <div className={styles.controller} aria-hidden="true">
      <div className={styles.controllerInner}>
        <div className={styles.joyStick}>
          <button
            aria-label=""
            className={`${styles.leftButton} ${styles.button} ${leftSelected ? `${styles.buttonActive}` : null} ${styles.dPad}`} onTouchStart={() => onButton("LEFT", true)} onTouchEnd={() => onButton("LEFT", false)} onMouseDown={() => onButton("LEFT", true)} onMouseUp={() => onButton("LEFT", false)}>
            <GameArrow active={leftSelected} direction="LEFT" />
          </button>

          <button
            className={`${styles.button} ${rightSelected ? `${styles.buttonActive}` : null} ${styles.dPad}`} onTouchStart={() => onButton("RIGHT", true)} onTouchEnd={() => onButton("RIGHT", false)} onMouseDown={() => onButton("RIGHT", true)} onMouseUp={() => onButton("RIGHT", false)}>
            <GameArrow active={rightSelected} direction="RIGHT" />
          </button>
        </div>
        <div className={styles.directions}>Use arrows{'\n'}to explore</div>
        <button
          className={`${styles.button} ${jumpSelected ? `${styles.buttonActive}` : null} ${styles.jumpButton}`} onTouchStart={() => onButton("JUMP", true)} onTouchEnd={() => onButton("JUMP", false)} onMouseDown={() => onButton("JUMP", true)} onMouseUp={() => onButton("JUMP", false)}>
          <GameArrow active={jumpSelected} direction="JUMP" />
        </button>
      </div>
    </div>
  );
}

export default Controller;
