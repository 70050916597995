const game = function({ _initX, _reactOnBonk, _reactOnKey, _onClick, _reactOnMove }) {
  let Mario = {};

  // util.js
  let Util = Mario.Util = {};

  Util.inherits = function(subclass, superclass) {
      function Surrogate() {};

      Surrogate.prototype = superclass.prototype;
      subclass.prototype = new Surrogate();
  }

  // input.js
  let pressedKeys = {};

  function setButtonPress(direction, status) {
    let key;

    switch(direction) {
    case "JUMP":
        key = 'JUMP'; break;
    case "LEFT":
        key = 'LEFT'; break;
    case "RIGHT":
        key = 'RIGHT'; break;
    }

    pressedKeys[key] = status;
  }


  function setKey(event, status) {
      let code = event.keyCode;
      let key;

      switch(code) {
      case 32:
          key = 'SPACE'; break;
      case 37:
          key = 'LEFT'; break;
      case 39:
          key = 'RIGHT'; break;
      case 40:
          key = 'DOWN'; break;
      case 38:
          key = 'JUMP'; break;
      case 90:
          key = 'RUN'; break;
      default:
          key = String.fromCharCode(code);
      }

      _reactOnKey(key, status);

      pressedKeys[key] = status;
  }

  const onKeyDown = function(e) {
    setKey(e, true);
  }

  const onKeyUp = function(e) {
    setKey(e, false);
  }

  const onBlur = function() {
    pressedKeys = {};
  }

  const onResize = function() {
    marioHolder = document.getElementById("marioHolder");
    canvas = marioHolder.querySelector('canvas');
    ctx = canvas.getContext('2d');
    let marioHolder = document.getElementById("marioHolder");
    canvas.width = marioHolder.clientWidth;
    ctx.scale(1, 1);
    ctx.webkitImageSmoothingEnabled = false;
    ctx.mozImageSmoothingEnabled = false;
    ctx.imageSmoothingEnabled = false;
    render();

  }

  document.addEventListener('keydown', onKeyDown);

  document.addEventListener('keyup', onKeyUp);

  window.addEventListener('blur', onBlur);

  window.addEventListener('resize', onResize);

  const removeListeners = function() {
    document.removeEventListener('keydown', onKeyDown);

    document.removeEventListener('keyup', onKeyUp);

    window.removeEventListener('blur', onBlur);

    window.removeEventListener('resize', onResize);
  }

  let input = {
      isDown: function(key) {
          return pressedKeys[key.toUpperCase()];
      },
      reset: function() {
        pressedKeys['RUN'] = false;
        pressedKeys['LEFT'] = false;
        pressedKeys['RIGHT'] = false;
        pressedKeys['DOWN'] = false;
        pressedKeys['JUMP'] = false;
      }
  };

  // resources.js
  let resourceCache = {};
  let loading = [];
  let readyCallbacks = [];

  // Load an image url or an array of image urls
  function load(urlOrArr) {
      if(urlOrArr instanceof Array) {
          urlOrArr.forEach(function(url) {
              _load(url);
          });
      }
      else {
          _load(urlOrArr);
      }
  }

  function _load(url) {
      if(resourceCache[url]) {
          return resourceCache[url];
      }
      else {
          let img = new Image();
          img.onload = function() {
              resourceCache[url] = img;

              if(isReady()) {
                  readyCallbacks.forEach(function(func) { func(); });
              }
          };
          resourceCache[url] = false;
          img.src = url;
      }
  }

  function get(url) {
      return resourceCache[url];
  }

  function isReady() {
      let ready = true;
      for(let k in resourceCache) {
          if(resourceCache.hasOwnProperty(k) &&
             !resourceCache[k]) {
              ready = false;
          }
      }
      return ready;
  }

  function onReady(func) {
      readyCallbacks.push(func);
  }

  let resources = {
    load: load,
    get: get,
    onReady: onReady,
    isReady: isReady
  };

  // sprite.js
  //
  let Intro = Mario.Intro = function() {

  }

  Intro.prototype.render = function(ctx, posx, posy, vX, vY) {

    let x = 0;
    let y = 0;
    let prevFill = ctx.fillStyle;
    ctx.fillStyle = 'white';
    ctx.fillRect(5 - vX, 0, Math.min(((canvas.width / 2) - 10), 200), 100);
    ctx.font = "14px courier";
    ctx.fillStyle = 'black';
    ctx.fillText("We make products", 15 - vX, 20);
    ctx.fillText("that make you happy", 15 - vX, 38);
    ctx.font = "10px courier";

    ctx.fillText("Move right for fun", 15 - vX, 60);
    ctx.fillText("or down for business", 15 - vX, 72);

    ctx.fillStyle = prevFill;
    // ctx.drawImage(resources.get(this.img), x + (1/3),y + (1/3), this.size[0] - (2/3), this.size[1] - (2/3), Math.round(posx - vX), Math.round(posy - vY), this.size[0],this.size[1]);
  }

  let Sprite = Mario.Sprite = function(img, pos, size, speed, frames, once) {
    this.pos = pos;
    this.size = size;
    this.speed = speed;
    this._index = 0;
    this.img = img;
    this.once = once;
    this.frames = frames;
  }

  Sprite.prototype.update = function(dt, gameTime) {
    if (gameTime && gameTime == this.lastUpdated) return;
    this._index += this.speed*dt;
    if (gameTime) this.lastUpdated = gameTime;
  }

  Sprite.prototype.setFrame = function(frame) {
    this._index = frame;
  }

  Sprite.prototype.render = function(ctx, posx, posy, vX, vY) {
    let frame;

    if (this.speed > 0) {
      let max = this.frames.length;
      let idx = Math.floor(this._index);
      frame = this.frames[idx % max];

      if (this.once && idx >= max) {
        this.done = true;
        return;
      }
    } else {
      frame = 0;
    }

    let x = this.pos[0];
    let y = this.pos[1];

    x += frame*this.size[0];
    ctx.drawImage(resources.get(this.img), x ,y , this.size[0], this.size[1], Math.floor(posx - vX), Math.floor(posy - vY), this.size[0],this.size[1]);
  }

  // entity.js

  let Entity = Mario.Entity = function(options) {
	  this.vel = [0,0];
	  this.acc = [0,0];
		this.standing = true;
	  this.pos = options.pos;
	  this.sprite = options.sprite;
	  this.hitbox = options.hitbox;
	  this.left = false;
	}

	Entity.prototype.render = function(ctx, vX, vY) {
		this.sprite.render(ctx, this.pos[0], this.pos[1], vX, vY)
	}

	Entity.prototype.collideWall = function(wall) {
		//the wall will always be a 16x16 block with hitbox = [0,0,16,16].
		if (this.pos[0] > wall.pos[0]) {
			//from the right
			this.pos[0] = wall.pos[0] + wall.hitbox[2] - this.hitbox[0];
			this.vel[0] = Math.max(0, this.vel[0]);
			this.acc[0] = Math.max(0, this.acc[0]);
		} else {
			this.pos[0] = wall.pos[0] + wall.hitbox[0] - this.hitbox[2] - this.hitbox[0];
			this.vel[0] = Math.min(0, this.vel[0]);
			this.acc[0] = Math.min(0, this.acc[0]);
		}
	}

	Entity.prototype.bump = function() {;}

  //pipe.js

  //there are too many possible configurations of pipe to capture in a reasonable
  //set of simple letiables. Joints, etc. are just too much.
  //To that end, the pipe class handles simple pipes, and we'll put together
  //anything more complex with individual props. OK? OK.
  let Pipe = Mario.Pipe = function(options) {
    this.pos = options.pos

    //NOTE: direction is the direction you move INTO the pipe.
    this.direction = options.direction
    this.destination = options.destination
    this.length = options.length;

    if (this.direction === "UP" || this.direction === "DOWN") {
      this.hitbox = [0,0, 32, this.length * 16];
      this.midsection = level.pipeUpMid;
      this.endsection = level.pipeTop;
    } else {
      this.hitbox = [0,0, 16*this.length, 32];
      this.midsection = level.pipeSideMid;
      this.endsection = level.pipeLeft;
    }
  }

  Pipe.prototype.checkPipe = function() {
    if (this.destination === undefined || !input.isDown(this.direction)) return;

    let h = player.power===0 ? 16 : 32;
    let x = Math.floor(player.pos[0]);
    let y = Math.floor(player.pos[1]);
    switch (this.direction) {
      case 'RIGHT': if (x === this.pos[0]-16 &&
                        y >= this.pos[1] &&
                        y+h <= this.pos[1]+32) {
                          player.pipe(this.direction, this.destination)
                        }
        break;
      case 'LEFT': if (x === this.pos[0]+16*this.length &&
                       y >= this.pos[1] &&
                       y+h <= this.pos[1]+32) {
                         player.pipe(this.direction, this.destination)
                       }
        break;
      case 'UP': if (y === this.pos[1] + 16*this.length &&
                     x >= this.pos[0] &&
                     x+16 <= this.pos[0]+32) {
                       player.pipe(this.direction, this.destination)
                     }
        break;
      case 'DOWN': if (y+h === this.pos[1] &&
                    x >= this.pos[0] &&
                    x+16 <= this.pos[0]+32) {
                      player.pipe(this.direction, this.destination);
                    }
        break;
    }
  }

  //Note to self: next time, decide on a convention for which thing checks for collisions
  //and stick to it. This is a pain.
  Pipe.prototype.checkCollisions = function() {
    let that = this;
    level.enemies.forEach (function(ent) {
      that.isCollideWith(ent);
    });

    level.items.forEach (function(ent) {
      that.isCollideWith(ent);
    });

    fireballs.forEach(function(ent){
      that.isCollideWith(ent)
    });

    if (!player.piping) this.isCollideWith(player);
  }

  Pipe.prototype.isCollideWith = function (ent) {
    //long story short: because we scan every item, and and one 'rubble' item is four things with separate positions
    //we'll crash without this line as soon as we destroy a block. OOPS.
    if (ent.pos === undefined) return;


    //the first two elements of the hitbox array are an offset, so let's do this now.
    let hpos1 = [Math.floor(this.pos[0] + this.hitbox[0]), Math.floor(this.pos[1] + this.hitbox[1])];
    let hpos2 = [Math.floor(ent.pos[0] + ent.hitbox[0]), Math.floor(ent.pos[1] + ent.hitbox[1])];

    //if the hitboxes actually overlap
    if (!(hpos1[0] > hpos2[0]+ent.hitbox[2] || (hpos1[0]+this.hitbox[2] < hpos2[0]))) {
      if (!(hpos1[1] > hpos2[1]+ent.hitbox[3] || (hpos1[1]+this.hitbox[3] < hpos2[1]))) {
        //if the entity is over the block, it's basically floor
        let center = hpos2[0] + ent.hitbox[2] / 2;
        if (Math.abs(hpos2[1] + ent.hitbox[3] - hpos1[1]) <= ent.vel[1]) {
          ent.vel[1] = 0;
          ent.pos[1] = hpos1[1] - ent.hitbox[3] - ent.hitbox[1];
          ent.standing = true;
          if (ent instanceof Mario.Player) {
            ent.jumping = 0;
          }
        } else if (Math.abs(hpos2[1] - hpos1[1] - this.hitbox[3]) > ent.vel[1] &&
        center + 2 >= hpos1[0] && center - 2 <= hpos1[0] + this.hitbox[2]) {
          //ent is under the block.
          ent.vel[1] = 0;
          ent.pos[1] = hpos1[1] + this.hitbox[3];
          if (ent instanceof Mario.Player) {
            ent.jumping = 0;
          }
        } else {
          //entity is hitting it from the side, we're a wall
          ent.collideWall(this);
        }
      }
    }
  }

  //we COULD try to write some shenanigans so that the check gets put into the
  //collision code, but there won't ever be more than a handful of pipes in a level
  //so the performance hit of scanning all of them is miniscule.
  Pipe.prototype.update = function(dt) {
    if (this.destination) this.checkPipe();
  }

  //http://stackoverflow.com/questions/11227809/why-is-processing-a-sorted-array-faster-than-an-unsorted-array
  //I honestly have no idea if javascript does this, but I feel like it makes sense
  //stylistically to prefer branching outside of loops when possible as convention

  //TODO: edit the spritesheet so UP and LEFT pipes aren't backwards.
  Pipe.prototype.render = function(ctx, vX, vY) {
    switch (this.direction) {
      case "DOWN":
        this.endsection.render(ctx, this.pos[0], this.pos[1], vX, vY);
        for (let i = 1; i < this.length; i++) {
          this.midsection.render(ctx, this.pos[0], this.pos[1]+i*16, vX, vY)
        }
        break;
      case "UP":
        this.endsection.render(ctx, this.pos[0], this.pos[1]+16*(this.length-1), vX, vY)
        for (let i=0; i < this.length - 1; i++) {
          this.midsection.render(ctx, this.pos[0], this.pos[1]+i*16, vX, vY)
        }
        break;
      case "RIGHT":
        this.endsection.render(ctx, this.pos[0], this.pos[1], vX, vY)
        for (let i = 1; i < this.length; i++) {
          this.midsection.render(ctx, this.pos[0]+16*i, this.pos[1], vX, vY)
        }
        break;
      case "LEFT":
        this.endsection.render(ctx, this.pos[0]+16*(this.length-1), this.pos[1], vX, vY)
        for (let i = 0; i < this.legth-1; i++) {
          this.midsection.render(ctx, this.pos[0], this.pos[1]+i*16, vX, vY)
        }
        break;
    }
  }

  // mushroom.js

  let Mushroom = Mario.Mushroom = function(pos) {
    this.spawning = false;
    this.waiting = 0;

    Mario.Entity.call(this, {
      pos: pos,
      sprite: level.superShroomSprite,
      hitbox: [0,0,16,16]
    });
  }

  Mario.Util.inherits(Mushroom, Mario.Entity);

  Mushroom.prototype.render = function(ctx, vX, vY) {
    if (this.spawning > 1) return;
    this.sprite.render(ctx, this.pos[0], this.pos[1], vX, vY);
  }

  Mushroom.prototype.spawn = function() {
    if (player.power > 0) {
      //replace this with a fire flower
      let ff = new Mario.Fireflower(this.pos)
      ff.spawn();
      return;
    }
    // sounds.itemAppear.play();
    this.idx = level.items.length;
    level.items.push(this);
    this.spawning = 12;
    this.targetpos = [];
    this.targetpos[0] = this.pos[0];
    this.targetpos[1] = this.pos[1] - 16;
  }

  Mushroom.prototype.update = function(dt) {
    if (this.spawning > 1) {
      this.spawning -= 1;
      if (this.spawning == 1) this.vel[1] = -.5;
      return;
    }
    if (this.spawning) {
      if (this.pos[1] <= this.targetpos[1]) {
        this.pos[1] = this.targetpos[1];
        this.vel[1] = 0;
        this.waiting = 5;
        this.spawning = 0;
        this.vel[0] = 1;
      }
    } else {
      this.acc[1] = 0.2;
    }

    if (this.waiting) {
      this.waiting -= 1;
    } else {
      this.vel[1] += this.acc[1];
      this.pos[0] += this.vel[0];
      this.pos[1] += this.vel[1];
      this.sprite.update(dt);
    }
  }

  Mushroom.prototype.collideWall = function() {
    this.vel[0] = -this.vel[0];
  }

  Mushroom.prototype.checkCollisions = function() {
    if(this.spawning) {
      return;
    }
    let h = this.pos[1] % 16 == 0 ? 1 : 2;
    let w = this.pos[0] % 16 == 0 ? 1 : 2;

    let baseX = Math.floor(this.pos[0] / 16);
    let baseY = Math.floor(this.pos[1] / 16);

    if (baseY + h > 15) {
      delete level.items[this.idx];
      return;
    }

    for (let i = 0; i < h; i++) {
      for (let j = 0; j < w; j++) {
        if (level.statics[baseY + i][baseX + j]) {
          level.statics[baseY + i][baseX + j].isCollideWith(this);
        }
        if (level.blocks[baseY + i][baseX + j]) {
          level.blocks[baseY + i][baseX + j].isCollideWith(this);
        }
      }
    }

    this.isPlayerCollided();
  }

  //we have access to player everywhere, so let's just do this.
  Mushroom.prototype.isPlayerCollided = function() {
    //the first two elements of the hitbox array are an offset, so let's do this now.
    let hpos1 = [this.pos[0] + this.hitbox[0], this.pos[1] + this.hitbox[1]];
    let hpos2 = [player.pos[0] + player.hitbox[0], player.pos[1] + player.hitbox[1]];

    //if the hitboxes actually overlap
    if (!(hpos1[0] > hpos2[0]+player.hitbox[2] || (hpos1[0]+this.hitbox[2] < hpos2[0]))) {
      if (!(hpos1[1] > hpos2[1]+player.hitbox[3] || (hpos1[1]+this.hitbox[3] < hpos2[1]))) {
        player.powerUp(this.idx);
      }
    }
  }

  Mushroom.prototype.bump = function() {
    this.vel[1] = -2;
  }

  // projectdesc
  //
  let Project = Mario.Project = function(pos) {

    Mario.Entity.call(this, {
      pos: pos,
      hitbox: [0,0,16,16]
    });
  }

  Mario.Util.inherits(Project, Mario.Entity);

  Project.prototype.render = function(ctx, vX, vY) {
    ctx.fillStyle = "green";
    ctx.fillRect(vX, vY, 50, 50);
  }

  // fireflower.js
  let Fireflower = Mario.Fireflower = function(pos) {
    this.spawning = false;
    this.waiting = 0;

    Mario.Entity.call(this, {
      pos: pos,
      sprite: level.fireFlowerSprite,
      hitbox: [0,0,16,16]
    });
  }

  Mario.Util.inherits(Fireflower, Mario.Entity);

  Fireflower.prototype.render = function(ctx, vX, vY) {
    if (this.spawning > 1) return;
    this.sprite.render(ctx, this.pos[0], this.pos[1], vX, vY);
  }

  Fireflower.prototype.spawn = function() {
    // sounds.itemAppear.play();
    this.idx = level.items.length;
    level.items.push(this);
    this.spawning = 12;
    this.targetpos = [];
    this.targetpos[0] = this.pos[0];
    this.targetpos[1] = this.pos[1] - 16;
  }

  Fireflower.prototype.update = function(dt) {
    if (this.spawning > 1) {
      this.spawning -= 1;
      if (this.spawning == 1) this.vel[1] = -.5;
      return;
    }
    if (this.spawning) {
      if (this.pos[1] <= this.targetpos[1]) {
        this.pos[1] = this.targetpos[1];
        this.vel[1] = 0;
        this.spawning = 0;
      }
    }

      this.vel[1] += this.acc[1];
      this.pos[0] += this.vel[0];
      this.pos[1] += this.vel[1];
      this.sprite.update(dt);
  }

  Fireflower.prototype.checkCollisions = function() {
    if (this.spawning) {return;}
    this.isPlayerCollided();
  }

  Fireflower.prototype.isPlayerCollided = function() {
    //the first two elements of the hitbox array are an offset, so let's do this now.
    let hpos1 = [this.pos[0] + this.hitbox[0], this.pos[1] + this.hitbox[1]];
    let hpos2 = [player.pos[0] + player.hitbox[0], player.pos[1] + player.hitbox[1]];

    //if the hitboxes actually overlap
    if (!(hpos1[0] > hpos2[0]+player.hitbox[2] || (hpos1[0]+this.hitbox[2] < hpos2[0]))) {
      if (!(hpos1[1] > hpos2[1]+player.hitbox[3] || (hpos1[1]+this.hitbox[3] < hpos2[1]))) {
        player.powerUp(this.idx);
      }
    }
  }

  //This should never be called, but just in case.
  Fireflower.prototype.bump = function() {;}

  // star.js

  let Star = Mario.Star = function(pos) {
    this.spawning = false;
    this.waiting = 0;

    Mario.Entity.call(this, {
      pos: pos,
      sprite: level.starSprite,
      hitbox: [0,0,16,16]
    });
  }

  Mario.Util.inherits(Star, Mario.Entity);

  Star.prototype.render = function(ctx, vX, vY) {
    if (this.spawning > 1) return;
    this.sprite.render(ctx, this.pos[0], this.pos[1], vX, vY);
  }

  Star.prototype.spawn = function() {
    this.idx = level.items.length;
    level.items.push(this);
    this.spawning = 12;
    this.targetpos = [];
    this.targetpos[0] = this.pos[0];
    this.targetpos[1] = this.pos[1] - 16;
  }

  Star.prototype.update = function(dt) {
    if (this.spawning > 1) {
      this.spawning -= 1;
      if (this.spawning == 1) this.vel[1] = -.5;
      return;
    }
    if (this.spawning) {
      if (this.pos[1] <= this.targetpos[1]) {
        this.pos[1] = this.targetpos[1];
        this.vel[1] = 0;
        this.waiting = 5;
        this.spawning = 0;
        this.vel[0] = 1;
      }
    } else {
      this.acc[1] = 0.2;
    }

    if (this.standing) {
      this.standing = false;
      this.vel[1] = -3;
    }

    if (this.waiting) {
      this.waiting -= 1;
    } else {
      this.vel[1] += this.acc[1];
      this.pos[0] += this.vel[0];
      this.pos[1] += this.vel[1];
      this.sprite.update(dt);
    }
  }

  Star.prototype.collideWall = function() {
    this.vel[0] = -this.vel[0];
  }

  Star.prototype.checkCollisions = function() {
    if(this.spawning) {
      return;
    }
    let h = this.pos[1] % 16 == 0 ? 1 : 2;
    let w = this.pos[0] % 16 == 0 ? 1 : 2;

    let baseX = Math.floor(this.pos[0] / 16);
    let baseY = Math.floor(this.pos[1] / 16);

    if (baseY + h > 15) {
      delete level.items[this.idx];
      return;
    }

    for (let i = 0; i < h; i++) {
      for (let j = 0; j < w; j++) {
        if (level.statics[baseY + i][baseX + j]) {
          level.statics[baseY + i][baseX + j].isCollideWith(this);
        }
        if (level.blocks[baseY + i][baseX + j]) {
          level.blocks[baseY + i][baseX + j].isCollideWith(this);
        }
      }
    }

    this.isPlayerCollided();
  }

  //we have access to player everywhere, so let's just do this.
  Star.prototype.isPlayerCollided = function() {
    //the first two elements of the hitbox array are an offset, so let's do this now.
    let hpos1 = [this.pos[0] + this.hitbox[0], this.pos[1] + this.hitbox[1]];
    let hpos2 = [player.pos[0] + player.hitbox[0], player.pos[1] + player.hitbox[1]];

    //if the hitboxes actually overlap
    if (!(hpos1[0] > hpos2[0]+player.hitbox[2] || (hpos1[0]+this.hitbox[2] < hpos2[0]))) {
      if (!(hpos1[1] > hpos2[1]+player.hitbox[3] || (hpos1[1]+this.hitbox[3] < hpos2[1]))) {
        player.star(this.idx);
      }
    }
  }

  Star.prototype.bump = function() {
    this.vel[1] = -2;
  }

  // fireball.js

  let Fireball = Mario.Fireball = function(pos) {
    this.hit = 0;
    this.standing = false;

    Mario.Entity.call(this, {
      pos: pos,
      sprite: new Mario.Sprite('sprites/items.png', [96, 144], [8,8], 5, [0,1,2,3]),
      hitbox: [0,0,8,8]
    });
  }

  Mario.Util.inherits(Fireball, Mario.Entity);

  Fireball.prototype.spawn = function(left) {
    // sounds.fireball.currentTime = 0;
    // sounds.fireball.play();
    if (fireballs[0]) {
      this.idx = 1;
      fireballs[1] = this;
    } else {
      this.idx = 0;
      fireballs[0] = this;
    }
    this.vel[0] = (left ? -5 : 5);
    this.standing = false;
    this.vel[1] = 0;
  }

  Fireball.prototype.render = function(ctx, vX, vY) {
    this.sprite.render(ctx, this.pos[0], this.pos[1], vX, vY);
  }

  Fireball.prototype.update = function(dt) {
    if (this.hit == 1) {
      this.sprite.pos = [96, 160];
      this.sprite.size = [16,16];
      this.sprite.frames = [0,1,2];
      this.sprite.speed = 8;
      this.hit += 1;
      return;
    } else if (this.hit == 5) {
      delete fireballs[this.idx];
      player.fireballs -= 1;
      return;
    } else if (this.hit) {
      this.hit += 1;
      return;
    }

    //In retrospect, the way collision is being handled is RIDICULOUS
    //but I don't have to use some horrible kludge for this.
    if (this.standing) {
      this.standing = false;
      this.vel[1] = -4;
    }

    this.acc[1] = 0.5;

    this.vel[1] += this.acc[1];
    this.pos[0] += this.vel[0];
    this.pos[1] += this.vel[1];
    if (this.pos[0] < vX || this.pos[0] > vX + 256) {
      this.hit = 1;
    }
    this.sprite.update(dt);
  }

  Fireball.prototype.collideWall = function() {
    if (!this.hit) this.hit = 1;
  }

  Fireball.prototype.checkCollisions = function() {
    if (this.hit) return;
    let h = this.pos[1] % 16 < 8 ? 1 : 2;
    let w = this.pos[0] % 16 < 8 ? 1 : 2;

    let baseX = Math.floor(this.pos[0] / 16);
    let baseY = Math.floor(this.pos[1] / 16);

    if (baseY + h > 15) {
      delete fireballs[this.idx];
      player.fireballs -= 1;
      return;
    }

    for (let i = 0; i < h; i++) {
      for (let j = 0; j < w; j++) {
        if (level.statics[baseY + i][baseX + j]) {
          level.statics[baseY + i][baseX + j].isCollideWith(this);
        }
        if (level.blocks[baseY + i][baseX + j]) {
          level.blocks[baseY + i][baseX + j].isCollideWith(this);
        }
      }
    }

    let that = this;
    level.enemies.forEach(function(enemy){
      if (enemy.flipping || enemy.pos[0] - vX > 336){ //stop checking once we get to far away dudes.
        return;
      } else {
        that.isCollideWith(enemy);
      }
    });
  }

  Fireball.prototype.isCollideWith = function(ent) {
    //the first two elements of the hitbox array are an offset, so let's do this now.
    let hpos1 = [this.pos[0] + this.hitbox[0], this.pos[1] + this.hitbox[1]];
    let hpos2 = [ent.pos[0] + ent.hitbox[0], ent.pos[1] + ent.hitbox[1]];

    //if the hitboxes actually overlap
    if (!(hpos1[0] > hpos2[0]+ent.hitbox[2] || (hpos1[0]+this.hitbox[2] < hpos2[0]))) {
      if (!(hpos1[1] > hpos2[1]+ent.hitbox[3] || (hpos1[1]+this.hitbox[3] < hpos2[1]))) {
        this.hit = 1;
        ent.bump();
      }
    }
  };

  Fireball.prototype.bump = function() {;}

  // coin.js

  let Coin = Mario.Coin = function(pos, sprite) {
    Mario.Entity.call(this, {
      pos: pos,
      sprite: sprite,
      hitbox: [0,0,16,16]
    });
    this.idx = level.items.length
  }

  Mario.Util.inherits(Coin, Mario.Entity);

  Coin.prototype.isPlayerCollided = function() {
    //the first two elements of the hitbox array are an offset, so let's do this now.
    let hpos1 = [this.pos[0] + this.hitbox[0], this.pos[1] + this.hitbox[1]];
    let hpos2 = [player.pos[0] + player.hitbox[0], player.pos[1] + player.hitbox[1]];

    //if the hitboxes actually overlap
    if (!(hpos1[0] > hpos2[0]+player.hitbox[2] || (hpos1[0]+this.hitbox[2] < hpos2[0]))) {
      if (!(hpos1[1] > hpos2[1]+player.hitbox[3] || (hpos1[1]+this.hitbox[3] < hpos2[1]))) {
        this.collect();
      }
    }
  }

  Coin.prototype.render = function(ctx, vX, vY) {
    this.sprite.render(ctx, this.pos[0], this.pos[1], vX, vY);
  }

  //money is not affected by gravity, you see.
  Coin.prototype.update = function(dt) {
    this.sprite.update(dt);
  }
  Coin.prototype.checkCollisions = function() {
    this.isPlayerCollided();
  }

  Coin.prototype.collect = function() {
    // sounds.coin.currentTime = 0.05;
    // sounds.coin.play();
    player.coins += 1;
    delete level.items[this.idx]
  }

  //bcoin.js

  let Bcoin = Mario.Bcoin = function(pos) {
    Mario.Entity.call(this, {
      pos: pos,
      sprite: level.bcoinSprite(),
      hitbox: [0,0,16,16]
    });
  }

  Mario.Util.inherits(Bcoin, Mario.Entity);

  //I'm not sure whether it makes sense to use an array for vel and acc here
  //in order to keep with convention, or to just use a single value, since
  //it's literally impossible for these to move left or right.
  Bcoin.prototype.spawn = function() {
    // sounds.coin.currentTime = 0.05;
    // sounds.coin.play();
    this.idx = level.items.length;
    level.items.push(this);
    this.active = true;
    this.vel = -12;
    this.targetpos = this.pos[1] - 32;
  }

  Bcoin.prototype.update = function(dt) {
    if (!this.active) return;

    if (this.vel > 0 && this.pos[1] >= this.targetpos) {
      player.coins += 1;
      //spawn a score thingy.
      delete level.items[this.idx];
    }

    this.acc = 0.75;
    this.vel += this.acc;
    this.pos[1] += this.vel;
    this.sprite.update(dt);
  }

  Bcoin.prototype.checkCollisions = function() {;}

  // goomba.js

  let Goomba = Mario.Goomba = function(pos, sprite) {
    this.dying = false;
    Mario.Entity.call(this, {
      pos: pos,
      sprite: sprite,
      hitbox: [0,0,16,16]
    });
    this.vel[0] = -0.5;
    this.idx = level.enemies.length;
  };

  Goomba.prototype.render = function(ctx, vX, vY) {
    this.sprite.render(ctx, this.pos[0], this.pos[1], vX, vY);
  };

  Goomba.prototype.update = function(dt, vX) {
    if (this.pos[0] - vX > 700) { //if we're too far away, do nothing.
      return;
    } else if (this.pos[0] - vX < -32) {
      delete level.enemies[this.idx];
    }

    if (this.dying) {
      this.dying -= 1;
      if (!this.dying) {
        delete level.enemies[this.idx];
      }
    }
    this.acc[1] = 0.2;
    this.vel[1] += this.acc[1];
    this.pos[0] += this.vel[0];
    this.pos[1] += this.vel[1];
    this.sprite.update(dt);
  };

  Goomba.prototype.collideWall = function() {
    this.vel[0] = -this.vel[0];
  };

  Goomba.prototype.checkCollisions = function() {
    if (this.flipping) {
      return;
    }

    let h = this.pos[1] % 16 === 0 ? 1 : 2;
    let w = this.pos[0] % 16 === 0 ? 1 : 2;

    let baseX = Math.floor(this.pos[0] / 16);
    let baseY = Math.floor(this.pos[1] / 16);

    if (baseY + h > 15) {
      delete level.enemies[this.idx];
      return;
    }

    for (let i = 0; i < h; i++) {
      for (let j = 0; j < w; j++) {
        if (level.statics[baseY + i][baseX + j]) {
          level.statics[baseY + i][baseX + j].isCollideWith(this);
        }
        if (level.blocks[baseY + i][baseX + j]) {
          level.blocks[baseY + i][baseX + j].isCollideWith(this);
        }
      }
    }
    let that = this;
    level.enemies.forEach(function(enemy){
      if (enemy === that) { //don't check collisions with ourselves.
        return;
      } else if (enemy.pos[0] - vX > 336){ //stop checking once we get to far away dudes.
        return;
      } else {
        that.isCollideWith(enemy);
      }
    });
    this.isCollideWith(player);
  };

  Goomba.prototype.isCollideWith = function(ent) {
    if (ent instanceof Mario.Player && (this.dying || ent.invincibility)) {
      return;
    }

    //the first two elements of the hitbox array are an offset, so let's do this now.
    let hpos1 = [this.pos[0] + this.hitbox[0], this.pos[1] + this.hitbox[1]];
    let hpos2 = [ent.pos[0] + ent.hitbox[0], ent.pos[1] + ent.hitbox[1]];

    //if the hitboxes actually overlap
    if (!(hpos1[0] > hpos2[0]+ent.hitbox[2] || (hpos1[0]+this.hitbox[2] < hpos2[0]))) {
      if (!(hpos1[1] > hpos2[1]+ent.hitbox[3] || (hpos1[1]+this.hitbox[3] < hpos2[1]))) {
        if (ent instanceof Mario.Player) { //if we hit the player
          if (ent.vel[1] > 0) { //then the goomba dies
            this.stomp();
          } else if (ent.starTime) {
            this.bump();
          } else { //or the player gets hit
            ent.damage();
          }
        } else {
          this.collideWall();
        }
      }
    }
  };

  Goomba.prototype.stomp = function() {
    // sounds.stomp.play();
    player.bounce = true;
    this.sprite.pos[0] = 32;
    this.sprite.speed = 0;
    this.vel[0] = 0;
    this.dying = 10;
  };

  Goomba.prototype.bump = function() {
    // sounds.kick.play();
    this.sprite.img = 'sprites/enemyr.png';
    this.flipping = true;
    this.pos[1] -= 1;
    this.vel[0] = 0;
    this.vel[1] = -2.5;
  };

  // koopa.js

  let Koopa = Mario.Koopa = function(pos, sprite, para) {
    this.dying = false;
    this.shell = false;

    this.para = para; //para. As in, is it a paratroopa?

    //So, funny story. The actual hitboxes don't reach all the way to the ground.
    //What that means is, as long as I use them to keep things on the floor
    //making the hitboxes accurate will make enemies sink into the ground.
    Mario.Entity.call(this, {
      pos: pos,
      sprite: sprite,
      hitbox: [2,8,12,24]
    });
    this.vel[0] = -0.5;
    this.idx = level.enemies.length;
  };

  Koopa.prototype.render = function(ctx, vX, vY) {
    this.sprite.render(ctx, this.pos[0], this.pos[1], vX, vY);
  };

  Koopa.prototype.update = function(dt, vX) {
    if (this.turn) {
      this.vel[0] = -this.vel[0];
      // if (this.shell) // sounds.bump.play();
      this.turn = false;
    }
    if (this.vel[0] != 0) {
      this.left = (this.vel[0] < 0);
    }

    if (this.left) {
      this.sprite.img = 'sprites/enemy.png';
    } else {
      this.sprite.img = 'sprites/enemyr.png';
    }

    if (this.pos[0] - vX > 700) { //if we're too far away, do nothing.
      return;
    } else if (this.pos[0] - vX < -32) {
      delete level.enemies[this.idx];
    }

    if (this.dying) {
      this.dying -= 1;
      if (!this.dying) {
        delete level.enemies[this.idx];
      }
    }

    if (this.shell) {
      if (this.vel[0] == 0) {
        this.shell -= 1;
        if (this.shell < 120) {
          this.sprite.speed = 5;
        }
        if (this.shell == 0) {
          this.sprite = level.koopaSprite();
          this.hitbox = [2,8,12,24]
          if (this.left) {
            this.sprite.img = 'sprites/enemyr.png';
            this.vel[0] = 0.5;
            this.left = false;
          } else {
            this.vel[0] = -0.5;
            this.left = true;
          }
          this.pos[1] -= 16;
        }
      } else {
        this.shell = 360;
        this.sprite.speed = 0;
        this.sprite.setFrame(0);
      }
    }
    this.acc[1] = 0.2;
    this.vel[1] += this.acc[1];
    this.pos[0] += this.vel[0];
    this.pos[1] += this.vel[1];
    this.sprite.update(dt);
  };

  Koopa.prototype.collideWall = function() {
    //This stops us from flipping twice on the same frame if we collide
    //with multiple wall tiles simultaneously.
    this.turn = true;
  };

  Koopa.prototype.checkCollisions = function() {
    let h = this.shell ? 1 : 2;
    if (this.pos[1] % 16 !== 0) {
      h += 1;
    }
    let w = this.pos[0] % 16 === 0 ? 1 : 2;

    let baseX = Math.floor(this.pos[0] / 16);
    let baseY = Math.floor(this.pos[1] / 16);

    if (baseY + h > 15) {
      delete level.enemies[this.idx];
      return;
    }

    if (this.flipping) {
      return;
    }

    for (let i = 0; i < h; i++) {
      for (let j = 0; j < w; j++) {
        if (level.statics[baseY + i][baseX + j]) {
          level.statics[baseY + i][baseX + j].isCollideWith(this);
        }
        if (level.blocks[baseY + i][baseX + j]) {
          level.blocks[baseY + i][baseX + j].isCollideWith(this);
        }
      }
    }
    let that = this;
    level.enemies.forEach(function(enemy){
      if (enemy === that) { //don't check collisions with ourselves.
        return;
      } else if (enemy.pos[0] - vX > 336){ //stop checking once we get to far away dudes.
        return;
      } else {
        that.isCollideWith(enemy);
      }
    });
    this.isCollideWith(player);
  };

  Koopa.prototype.isCollideWith = function(ent) {
    if (ent instanceof Mario.Player && (this.dying || ent.invincibility)) {
      return;
    }

    //the first two elements of the hitbox array are an offset, so let's do this now.
    let hpos1 = [this.pos[0] + this.hitbox[0], this.pos[1] + this.hitbox[1]];
    let hpos2 = [ent.pos[0] + ent.hitbox[0], ent.pos[1] + ent.hitbox[1]];

    //if the hitboxes actually overlap
    if (!(hpos1[0] > hpos2[0]+ent.hitbox[2] || (hpos1[0]+this.hitbox[2] < hpos2[0]))) {
      if (!(hpos1[1] > hpos2[1]+ent.hitbox[3] || (hpos1[1]+this.hitbox[3] < hpos2[1]))) {
        if (ent instanceof Mario.Player) {
          if (ent.vel[1] > 0) {
            player.bounce = true;
          }
          if (this.shell) {
            // sounds.kick.play();
            if (this.vel[0] === 0) {
              if (ent.left) { //I'm pretty sure this isn't the real logic.
                this.vel[0] = -4;
              } else {
                this.vel[0] = 4;
              }
            } else {
              if (ent.bounce) {
                this.vel[0] = 0;
              } else ent.damage();
            }
          } else if (ent.vel[1] > 0) { //then we get BOPPED.
            this.stomp();
          } else { //or the player gets hit
            ent.damage();
          }
        } else {
          if (this.shell && (ent instanceof Mario.Goomba)) {
            ent.bump();
          } else this.collideWall();
        }
      }
    }
  };

  Koopa.prototype.stomp = function() {
    //Turn this thing into a shell if it isn't already. Kick it if it is.
    player.bounce = true;
    if (this.para) {
      this.para = false;
      this.sprite.pos[0] -= 32;
    } else {
      // sounds.stomp.play();
      this.shell = 360;
      this.sprite.pos[0] += 64;
      this.sprite.pos[1] += 16;
      this.sprite.size = [16,16];
      this.hitbox = [2,0,12,16];
      this.sprite.speed = 0;
      this.frames = [0,1];
      this.vel = [0,0];
      this.pos[1] += 16;
    }

  };

  Koopa.prototype.bump = function() {
    // sounds.kick.play();
    if (this.flipping) return;
    this.flipping = true;
    this.sprite.pos = [160, 0];
    this.sprite.size = [16,16];
    this.hitbox = [2, 0, 12, 16];
    this.sprite.speed = 0;
    this.vel[0] = 0;
    this.vel[1] = -2.5;
  };

  // floor.js

  let Floor = Mario.Floor = function(pos, sprite) {

		Mario.Entity.call(this, {
			pos: pos,
			sprite: sprite,
			hitbox: [0,0,16,16]
		});
	}

	Mario.Util.inherits(Floor, Mario.Entity);

	Floor.prototype.isCollideWith = function (ent) {
		//the first two elements of the hitbox array are an offset, so let's do this now.
		let hpos1 = [Math.floor(this.pos[0] + this.hitbox[0]), Math.floor(this.pos[1] + this.hitbox[1])];
		let hpos2 = [Math.floor(ent.pos[0] + ent.hitbox[0]), Math.floor(ent.pos[1] + ent.hitbox[1])];

		//if the hitboxes actually overlap
		if (!(hpos1[0] > hpos2[0]+ent.hitbox[2] || (hpos1[0]+this.hitbox[2] < hpos2[0]))) {
			if (!(hpos1[1] > hpos2[1]+ent.hitbox[3] || (hpos1[1]+this.hitbox[3] < hpos2[1]))) {
				if (!this.standing) {
					ent.bump();
				} else {
					//if the entity is over the block, it's basically floor
					let center = hpos2[0] + ent.hitbox[2] / 2;
					if (Math.abs(hpos2[1] + ent.hitbox[3] - hpos1[1]) <= ent.vel[1]) {
						if (level.statics[(this.pos[1] / 16) - 1][this.pos[0] / 16]) {return};
						ent.vel[1] = 0;
						ent.pos[1] = hpos1[1] - ent.hitbox[3] - ent.hitbox[1];
						ent.standing = true;
						if (ent instanceof Mario.Player) {
							ent.jumping = 0;
						}
					} else if (Math.abs(hpos2[1] - hpos1[1] - this.hitbox[3]) > ent.vel[1] &&
					center + 2 >= hpos1[0] && center - 2 <= hpos1[0] + this.hitbox[2]) {
						//ent is under the block.
						ent.vel[1] = 0;
						ent.pos[1] = hpos1[1] + this.hitbox[3];
						if (ent instanceof Mario.Player) {
							this.bonk(ent.power);
							ent.jumping = 0;
						}
					} else {
						//entity is hitting it from the side, we're a wall
						ent.collideWall(this);
					}
				}
			}
		}
	}

	Floor.prototype.bonk = function() {;}

  // block.js
  let Block = Mario.Block = function(options) {
    this.item = options.item;
    this.usedSprite = options.usedSprite;
    this.projectId = options.projectId;
    this.breakable = options.breakable;

    Mario.Entity.call(this, {
      pos: options.pos,
      sprite: options.sprite,
      hitbox: [0,0,16,16]
    });

    this.standing = true;
  }

  Mario.Util.inherits(Block, Mario.Floor);

  Block.prototype.break = function() {
    // sounds.breakBlock.play();
    (new Mario.Rubble()).spawn(this.pos);
    let x = this.pos[0] / 16, y = this.pos[1] / 16;
    delete level.blocks[y][x];
  }

  Block.prototype.bonk = function(power) {
    // sounds.bump.play();
    //
    if (power > 0 && this.breakable) {
      this.break();
    } else if (this.standing){
      this.standing = false;
      if (this.item) {
        this.item.spawn();
        this.item = null;
      }
      this.opos = [];
      this.opos[0] = this.pos[0];
      this.opos[1] = this.pos[1];
      if (this.projectId != null) {
        _reactOnBonk(this.projectId);
      } else {
        this.sprite = this.usedSprite;
      }

      this.vel[1] = -2;
    }
  }

  Block.prototype.update = function(dt, gameTime) {
    if (!this.standing) {
      if (this.pos[1] < this.opos[1] - 8) {
        this.vel[1] = 2;
      }
      if (this.pos[1] > this.opos[1]) {
        this.vel[1] = 0;
        this.pos = this.opos;
        if (this.osprite) {
          this.sprite = this.osprite;
        }
        this.standing = true;
      }
    } else {
      if (this.sprite === this.usedSprite) {
        let x = this.pos[0] / 16, y = this.pos[1] / 16;
        level.statics[y][x] = new Mario.Floor(this.pos, this.usedSprite);
        delete level.blocks[y][x];
      }
    }

    this.pos[1] += this.vel[1];
    this.sprite.update(dt, gameTime);
  }

  // rubble.js

  let Rubble = Mario.Rubble = function() {
    this.sprites = [];
    this.poss = [];
    this.vels = [];
  }

  Rubble.prototype.spawn = function(pos) {
    this.idx = level.items.length;
    level.items.push(this);
    this.sprites[0] = level.rubbleSprite();
    this.sprites[1] = level.rubbleSprite();
    this.sprites[2] = level.rubbleSprite();
    this.sprites[3] = level.rubbleSprite();
    this.poss[0] = pos;
    this.poss[1] = [ pos[0] + 8, pos[1] ];
    this.poss[2] = [ pos[0], pos[1] + 8 ];
    this.poss[3] = [ pos[0] + 8, pos[1] + 8 ];
    this.vels[0] = [-1.25, -5];
    this.vels[1] = [1.25, -5];
    this.vels[2] = [-1.25, -3];
    this.vels[3] = [1.25, -3];
  }

  Rubble.prototype.update = function(dt) {
    for(let i = 0; i < 4; i++) {
      if (this.sprites[i]===undefined) continue;
      this.vels[i][1] += .3;
      this.poss[i][0] += this.vels[i][0];
      this.poss[i][1] += this.vels[i][1];
      this.sprites[i].update(dt);
      if (this.poss[i][1] > 256) {
        delete this.sprites[i];
      }
    }
    if (this.sprites.every(function (el) {return !el})) {
      delete level.items[this.idx];
    }
  }

  //You might argue that things that can't collide are more like scenery
  //but these move and need to be deleted, and i'd rather deal with the 1d array.
  Rubble.prototype.checkCollisions = function() {;}

  Rubble.prototype.render = function() {
    for(let i = 0; i < 4; i++) {
      if (this.sprites[i] === undefined) continue;
      this.sprites[i].render(ctx, this.poss[i][0], this.poss[i][1], vX, vY);
    }
  }

  //prop.js

  //props do even less than entities, so they don't need to inherit really
  let Prop = Mario.Prop = function(pos, sprite) {
    this.pos = pos;
    this.sprite = sprite;
  }

  //but we will be using the same Render, more or less.
  Prop.prototype.render = function(ctx, vX, vY) {
    this.sprite.render(ctx, this.pos[0], this.pos[1], vX, vY);
  }

  // player.js

  let Player = Mario.Player = function(pos) {
    //I know, I know, there are a lot of letiables tracking Mario's state.
    //Maybe these can be consolidated some way? We'll see once they're all in.
    this.power = 0;
    this.coins = 0;
    this.powering = [];
    this.bounce = false;
    this.jumping = 0;
    this.canJump = true;
    this.invincibility = 0;
    this.crouching = false;
    this.fireballs = 0;
    this.runheld = false;
    this.noInput = false;
    this.targetPos = [];
    this.initStroll = true;
    this.initLeft = 0;

    Mario.Entity.call(this, {
      pos: pos,
      sprite: new Mario.Sprite('sprites/player.png', [80,32],[16,16],0),
      hitbox: [0,0,16,16]
    });
  };

  Mario.Util.inherits(Player, Mario.Entity);

  Player.prototype.run = function() {
    this.initStroll = false;
    this.maxSpeed = 2.5;
    if (this.power == 2 && !this.runheld) {
      this.shoot();
    }
    this.runheld = true;
  }

  Player.prototype.shoot = function() {
    if (this.fireballs >= 2) return; //Projectile limit!
    this.fireballs += 1;
    let fb = new Mario.Fireball([this.pos[0]+8,this.pos[1]]); //I hate you, Javascript.
    fb.spawn(this.left);
    this.shooting = 2;
  }

  Player.prototype.noRun = function() {
    this.maxSpeed = 1.5;
    this.moveAcc = 0.07;
    this.runheld = false;
  }

  Player.prototype.moveRight = function() {
    this.initStroll = false;
    //we're on the ground
    if (this.vel[1] === 0 && this.standing) {
      if (this.crouching) {
        this.noWalk();
        return;
      }
      this.acc[0] = this.moveAcc;
      this.left = false;
    } else {
      this.acc[0] = this.moveAcc;
    }
  };

  Player.prototype.moveLeft = function() {
    this.initStroll = false;
    if (this.vel[1] === 0 && this.standing) {
      if (this.crouching) {
        this.noWalk();
        return;
      }
      this.acc[0] = -this.moveAcc;
      this.left = true;
    } else {
      this.acc[0] = -this.moveAcc;
    }
  };

  Player.prototype.noWalk = function() {
    this.maxSpeed = 0;
    if (this.vel[0] === 0) return;

    if (Math.abs(this.vel[0]) <= 0.1) {
      this.vel[0] = 0;
      this.acc[0] = 0;
    }

  };

  Player.prototype.crouch = function() {
    if (this.power === 0) {
      this.crouching = false;
      return;
    }

    if (this.standing) this.crouching = true;
  }

  Player.prototype.noCrouch = function() {
    this.crouching = false;
  }

  Player.prototype.jump = function() {
    this.initStroll = false;
    if (this.vel[1] > 0) {
      return;
    }
    if (this.jumping) {
      this.jumping -= 1;
    } else if (this.standing && this.canJump) {
      this.jumping = 20;
      this.canJump = false;
      this.standing = false;
      this.vel[1] = -6;
      if (this.power === 0) {
        // sounds.smallJump.currentTime = 0;
        // sounds.smallJump.play();
      } else {
        // sounds.bigJump.currentTime = 0;
        // sounds.bigJump.play();
      }
    }
  };

  Player.prototype.noJump = function() {
    this.canJump = true;
    if (this.jumping) {
      if (this.jumping <= 16) {
        this.vel[1] = 0;
        this.jumping = 0;
      } else this.jumping -= 1;
    }
  };

  Player.prototype.setAnimation = function() {
    if (this.dying) return;

    if (this.starTime) {
      let index;
      if (this.starTime > 60)
        index = Math.floor(this.starTime / 2) % 3;
      else index = Math.floor(this.starTime / 8) % 3;

      this.sprite.pos[1] = level.invincibility[index];
      if (this.power == 0) {
        this.sprite.pos[1] += 32;
      }
      this.starTime -= 1;
      if (this.starTime == 0) {
        switch(this.power) {
          case 0: this.sprite.pos[1] = 32; break;
          case 1: this.sprite.pos[1] = 0; break;
          case 2: this.sprite.pos[1] = 96; break;
        }
      }
    }
    //okay cool, now set the sprite
    if (this.crouching) {
      this.sprite.pos[0] = 176;
      this.sprite.speed = 0;
      return;
    }

    if (this.jumping) {
      this.sprite.pos[0] = 160;
      this.sprite.speed = 0;
    } else if (this.standing) {
      if (Math.abs(this.vel[0]) > 0) {
        if (this.vel[0] * this.acc[0] >= 0) {
          this.sprite.pos[0] = 96;
          this.sprite.frames = [0,1,2];
          if (this.vel[0] < 0.2) {
            this.sprite.speed = 5;
          } else {
            this.sprite.speed = Math.abs(this.vel[0]) * 8;
          }
        } else if ((this.vel[0] > 0 && this.left) || (this.vel[0] < 0 && !this.left)){
          this.sprite.pos[0] = 144;
          this.sprite.speed = 0;
        }
      } else {
        this.sprite.pos[0] = 80;
        this.sprite.speed = 0;
      }
      if (this.shooting) {
        this.sprite.pos[0] += 160;
        this.shooting -= 1;
      }
    }

    if (this.flagging) {
      this.sprite.pos[0] = 192;
      this.sprite.frames = [0,1];
      this.sprite.speed = 10;
      if (this.vel[1] === 0) this.sprite.frames = [0];
    }

    //which way are we facing?
    if (this.left) {
      this.sprite.img = 'sprites/playerl.png';
    } else {
      this.sprite.img = 'sprites/player.png';
    }
  };

  Player.prototype.update = function(dt, vX) {
    const prevXPost = this.pos[0];

    if (this.powering.length !== 0) {
      let next = this.powering.shift();
      if (next == 5) return;
      this.sprite.pos = this.powerSprites[next];
      this.sprite.size = this.powerSizes[next];
      this.pos[1] += this.shift[next];
      if (this.powering.length === 0) {
        delete level.items[this.touchedItem];
      }
      return;
    }

    if (this.invincibility) {
      this.invincibility -= Math.round(dt * 60);
    }

    if (this.waiting) {
      this.waiting -= dt;
      if (this.waiting <= 0) {
        this.waiting = 0;
      } else return;
    }

    if (this.bounce) {
      this.bounce = false;
      this.standing = false;
      this.vel[1] = -3;
    }

    if (this.pos[0] <= vX) {
      this.pos[0] = vX;
      this.vel[0] = Math.max(this.vel[0], 0);
    }

    // At the end
    if ((player.pos[0] + 16) >= (16 * maxGround) && this.acc[0] > 0) {
      this.pos[0] = player.pos[0];
      this.vel[0] = 0;
      this.acc = [0,0];
    }

    if (Math.abs(this.vel[0]) > this.maxSpeed) {
      this.vel[0] -= 0.05 *  this.vel[0] / Math.abs(this.vel[0]);
      this.acc[0] = 0;
    }

    if (this.dying){
      if (this.pos[1] < this.targetPos[1]) {
        this.vel[1] = 1;
      }
      this.dying -= 1 * dt;
      if (this.dying <= 0) {
        player = new Mario.Player(level.playerPos);
        level.loader.call();
        input.reset();
        _reactOnMove(0);
      }
    }
    else {
      this.acc[1] = 0.25
      if (this.pos[1] > 240) {
        this.die();
      }
    }

    if (this.piping) {
      this.acc = [0,0];
      let pos = [Math.round(this.pos[0]), Math.round(this.pos[1])]
      if (pos[0] === this.targetPos[0] && pos[1] === this.targetPos[1]) {
        this.piping = false;
        this.pipeLoc.call();
      }
    }

    if (this.flagging) {
      this.acc = [0,0];
    }

    if (this.exiting) {
      this.left = false;
      this.flagging = false;
      this.vel[0] = 1.5;
      if (this.pos[0] >= this.targetPos[0]) {
        this.sprite.size = [0,0];
        this.vel = [0,0];
        window.setTimeout(function() {
          player.sprite.size = player.power===0 ? [16,16] : [16,32];
          player.exiting = false;
          player.noInput = false;
          level.loader();
          if (player.power !== 0) player.pos[1] -= 16;
          // music.overworld.currentTime = 0;
        }, 5000);
      }
    }

    if (this.initStroll) {
      if (this.initLeft > 0) {
        if (this.vel[0] >= -0.35) {
          this.vel[0] -= 0.2;
        }
        this.left = true;
      } else {
        if (this.vel[0] <= 0.35) {
          this.vel[0] += 0.2;
        }
        this.left = false;
      }
      this.initLeft -= 1;
      this.vel[1] += this.acc[1];
      this.pos[0] += this.vel[0];
      this.pos[1] += this.vel[1];
      this.setAnimation();
      if (this.initLeft < -90) {
        this.initLeft = 90;
      }
    }  else {

      //approximate acceleration
      this.vel[0] += this.acc[0];
      this.vel[1] += this.acc[1];
      this.pos[0] += this.vel[0];
      this.pos[1] += this.vel[1];
      this.setAnimation();
      if (prevXPost != this.pos[0]) {
        _reactOnMove(this.pos[0]);
      }
    }
    this.sprite.update(dt);
  };

  Player.prototype.checkCollisions = function() {
    if (this.piping || this.dying) return;
    //x-axis first!
    let h = this.power > 0 ? 2 : 1;
    let w = 1;
    if (this.pos[1] % 16 !== 0) {
      h += 1;
    }
    if (this.pos[0] % 16 !== 0) {
      w += 1;
    }
    let baseX = Math.floor(this.pos[0] / 16);
    let baseY = Math.floor(this.pos[1] / 16);

    for (let i = 0; i < h; i++) {
      if (baseY + i < 0 || baseY + i >= 15) continue;
      for (let j = 0; j < w; j++) {
        if (baseY < 0) { i++;}
        if (level.statics[baseY + i][baseX + j]) {
          level.statics[baseY + i][baseX + j].isCollideWith(this);
        }
        if (level.blocks[baseY + i][baseX + j]) {
          level.blocks[baseY + i][baseX + j].isCollideWith(this);
        }
      }
    }
  };

  Player.prototype.powerUp = function(idx) {
    // sounds.powerup.play();
    this.powering = [0,5,2,5,1,5,2,5,1,5,2,5,3,5,1,5,2,5,3,5,1,5,4];
    this.touchedItem = idx;

    if (this.power === 0) {
      this.sprite.pos[0] = 80;
      let newy = this.sprite.pos[1] - 32;
      this.powerSprites = [[80, newy+32], [80, newy+32], [320, newy], [80, newy], [128, newy]];
      this.powerSizes = [[16,16],[16,16],[16,32],[16,32],[16,32]];
      this.shift = [0,16,-16,0,-16];
      this.power = 1;
      this.hitbox = [0,0,16,32];
    } else if (this.power == 1) {
      let curx = this.sprite.pos[0];
      this.powerSprites = [[curx, 96], [curx, level.invincibility[0]],
        [curx, level.invincibility[1]], [curx, level.invincibility[2]],
        [curx, 96]];
      // this.powerSizes[[16,32],[16,32],[16,32],[16,32],[16,32]];
      this.shift = [0,0,0,0,0];
      this.power = 2;
    } else {
      this.powering = [];
      delete level.items[idx];
      //no animation, but we play the sound and you get 5000 points.
    }
  };

  Player.prototype.damage = function() {
    if (this.power === 0) { //if you're already small, you dead!
      this.die();
    } else { //otherwise, you get turned into small mario
      // // sounds.pipe.play();
      this.powering = [0,5,1,5,2,5,1,5,2,5,1,5,2,5,1,5,2,5,1,5,2,5,3];
      this.shift = [0,16,-16,16];
      this.sprite.pos = [160, 0];
      this.powerSprites = [[160,0], [240, 32], [240, 0], [160, 32]];
      this.powerSizes = [[16, 32], [16,16], [16,32], [16,16]];
      this.invincibility = 120;
      this.power = 0;
      this.hitbox = [0,0,16,16];
    }
  };

  Player.prototype.die = function () {
    //TODO: rewrite the way sounds work to emulate the channels of an NES.
    // // music.overworld.pause();
    // // music.underground.pause();
    // // music.overworld.currentTime = 0;
    // // music.death.play();
    this.noWalk();
    this.noRun();
    this.noJump();

    this.acc[0] = 0;
    this.sprite.pos = [176, 32];
    this.sprite.speed = 0;
    this.power = 0;
    this.waiting = 0.25;
    this.dying = 1;

    if (this.pos[1] < 240) { //falling into a pit doesn't do the animation.
      this.targetPos = [this.pos[0], this.pos[1]-128];
      this.vel = [0,-5];
    } else {
      this.vel = [0,0];
      this.targetPos = [this.pos[0], this.pos[1] - 16];
      this.initStroll = true;
    }
  };

  Player.prototype.star = function(idx) {
    delete level.items[idx];
    this.starTime = 660;
  }

  Player.prototype.pipe = function(direction, destination) {
    // sounds.pipe.play();
    this.piping = true;
    this.pipeLoc = destination;
    switch(direction) {
      case "LEFT":
        this.vel = [-1,0];
        this.targetPos = [Math.round(this.pos[0]-16), Math.round(this.pos[1])]
        break;
      case "RIGHT":
        this.vel = [1,0];
        this.targetPos = [Math.round(this.pos[0]+16), Math.round(this.pos[1])]
        break;
      case "DOWN":
        this.vel = [0,1];
        this.targetPos = [Math.round(this.pos[0]), Math.round(this.pos[1]+this.hitbox[3])]
        break;
      case "UP":
        this.vel = [0,-1];
        this.targetPos = [Math.round(this.pos[0]), Math.round(this.pos[1]-this.hitbox[3])]
        break;
    }
  }

  Player.prototype.flag = function() {
    this.noInput = true;
    this.flagging = true;
    this.vel = [0, 2];
    this.acc = [0, 0];
  }

  Player.prototype.exit = function() {
    this.pos[0] += 16;
    this.targetPos[0] = level.exit * 16;
    this.left = true;
    this.setAnimation();
    this.waiting = 1;
    this.exiting = true;
  }

  // flag.js
  let Flag = Mario.Flag = function(pos) {
    //afaik flags always have the same height and Y-position
    this.pos = [pos, 49];
    this.hitbox = [0,0,0,0];
    this.vel = [0,0];
    this.acc = [0,0];
  }

  Flag.prototype.collideWall = function() {;
  }

  Flag.prototype.update = function(dt){
    if (!this.done && this.pos[1] >= 170) {
      this.vel = [0,0];
      this.pos[1] = 170;
      console.log('hi')
      player.exit();
      this.done = true;
    }
    this.pos[1] += this.vel[1];
  }

  Flag.prototype.checkCollisions = function() {
    this.isPlayerCollided();
  }

  Flag.prototype.isPlayerCollided = function() {
    if (this.hit) return;
    if (player.pos[0] + 8 >= this.pos[0]) {
      // music.overworld.pause();
      // sounds.flagpole.play();
      setTimeout(function() {
        // music.clear.play();
      }, 2000);
      this.hit = true;
      player.flag();
      this.vel = [0, 2];
    }
  }

  Flag.prototype.render = function() {
    level.flagpoleSprites[2].render(ctx, this.pos[0]-8, this.pos[1], vX, vY);
  }

  //level.js
  let px = 0;
  let py = 0;

  let Level = Mario.Level = function(options) {
    this.playerPos = options.playerPos;
    this.scrolling = options.scrolling;
    this.loader = options.loader;
    this.background = options.background;
    this.exit = options.exit;

    this.floorSprite = options.floorSprite;
    this.floorSprite2 = options.floorSprite2;
    this.seaSprite = options.seaSprite;
    this.cloudSprite = options.cloudSprite;
    this.wallSprite = options.wallSprite;
    this.brickSprite = options.brickSprite;
    this.rubbleSprite = options.rubbleSprite;
    this.brickBounceSprite = options.brickBounceSprite;
    this.ublockSprite = options.ublockSprite;
    this.superShroomSprite = options.superShroomSprite;
    this.fireFlowerSprite = options.fireFlowerSprite;
    this.starSprite = options.starSprite;
    this.coinSprite = options.coinSprite;
    this.bcoinSprite = options.bcoinSprite;
    this.bounceSprite = options.bounceSprite;
    this.projectSprites = options.projectSprites
    this.goombaSprite = options.goombaSprite;
    this.koopaSprite = options.koopaSprite;

    //prop pipe sprites, to be phased out
    this.pipeLEndSprite = options.pipeLEndSprite;
    this.pipeREndSprite = options.pipeREndSprite;
    this.pipeLMidSprite = options.pipeLMidSprite;
    this.pipeRMidSprite = options.pipeRMidSprite;

    //real pipe sprites, use these.
    this.pipeUpMid = options.pipeUpMid;
    this.pipeSideMid = options.pipeSideMid;
    this.pipeLeft = options.pipeLeft;
    this.pipeTop = options.pipeTop;

    this.flagpoleSprites = options.flagPoleSprites;

    this.LPipeSprites = options.LPipeSprites;
    this.cloudSprites = options.cloudSprites;
    this.hillSprites = options.hillSprites;
    this.waveSprites = options.waveSprites;
    this.palmSprites = options.palmSprites;
    this.castleSprites = options.castleSprites;
    this.bushSprite = options.bushSprite;
    this.bushSprites = options.bushSprites;
    this.qblockSprite = options.qblockSprite;

    this.invincibility = options.invincibility;
    this.statics = [];
    this.scenery = [];
    this.blocks = [];
    this.enemies = [];
    this.items = [];
    this.pipes = [];
    this.intro = new Mario.Intro();

    for (let i = 0; i < 15; i++) {
      this.statics[i] = [];
      this.scenery[i] = [];
      this.blocks[i] = [];
    }

  };

  Level.prototype.putSea = function() {
    for (let i = 0; i < 208; i++) {
      this.scenery[14][i] = new Mario.Prop([16*i,224], this.seaSprite);
    }
  }

  Level.prototype.putFloor = function(start, end) {
    for (let i = start; i < end; i++) {
      this.statics[13][i] = new Mario.Floor([16*i,208], this.floorSprite2);
      this.statics[14][i] = new Mario.Floor([16*i,224], this.floorSprite);
    }
  };

  Level.prototype.putGoomba = function(x, y) {
    this.enemies.push(new Mario.Goomba([16*x, 16*y], this.goombaSprite() ));
  };

  Level.prototype.putKoopa = function(x, y) {
    this.enemies.push(new Mario.Koopa([16*x, 16*y], this.koopaSprite(), false));
  };

  Level.prototype.putWall = function(x, y, height) {
    //y is the bottom of the wall in this case.
    for (let i = y-height; i < y; i++) {
      this.statics[i][x] = new Mario.Floor([16*x, 16*i], this.wallSprite);
    }
  };

  Level.prototype.putPipe = function(x, y, height) {
    for (let i = y - height; i < y; i++) {
      if (i === y - height) {
        this.statics[i][x] = new Mario.Floor([16*x, 16*i], this.pipeLEndSprite);
        this.statics[i][x+1] = new Mario.Floor([16*x+16, 16*i], this.pipeREndSprite);
      } else {
        this.statics[i][x] = new Mario.Floor([16*x, 16*i], this.pipeLMidSprite);
        this.statics[i][x+1] = new Mario.Floor([16*x+16, 16*i], this.pipeRMidSprite);
      }
    }
  };

  //sometimes, pipes don't go straight up and down.
  Level.prototype.putLeftPipe = function(x,y) {
    this.statics[y][x] = new Mario.Floor([16*x, 16*y], this.LPipeSprites[0]);
    this.statics[y+1][x] = new Mario.Floor([16*x,16*(y+1)], this.LPipeSprites[1]);
    this.statics[y][x+1] = new Mario.Floor([16*(x+1),16*y], this.LPipeSprites[2]);
    this.statics[y+1][x+1] = new Mario.Floor([16*(x+1),16*(y+1)], this.LPipeSprites[3]);
    this.statics[y][x+2] = new Mario.Floor([16*(x+2),16*y], this.LPipeSprites[4]);
    this.statics[y+1][x+2] = new Mario.Floor([16*(x+2),16*(y+1)], this.LPipeSprites[5]);
  };

  Level.prototype.putCoin = function(x, y) {
    this.items.push(new Mario.Coin(
      [x*16, y*16],
      this.coinSprite()
    ));
  };

  Level.prototype.putCloud = function(x, y) {
    this.scenery[y][x] = new Mario.Prop([x*16, y*16], this.cloudSprite);
  };

  Level.prototype.putQBlock = function(x, y, item) {
    this.blocks[y][x] = new Mario.Block( {
      pos: [x*16, y*16],
      item: item,
      sprite: this.qblockSprite,
      usedSprite: this.ublockSprite
    });
  };

  Level.prototype.putProjectBlock = function(x, y, item, projectId) {
    this.blocks[y][x] = new Mario.Block( {
      pos: [x*16, y*16],
      item: item,
      projectId,
      sprite: this.projectSprites[projectId],
      usedSprite: this.ublockSprite
    });
  };

  Level.prototype.putBrick = function(x,y,item) {
    this.blocks[y][x] = new Mario.Block({
      pos: [x*16, y*16],
      item: item,
      sprite: this.brickSprite,
      bounceSprite: this.brickBounceSprite,
      usedSprite: this.ublockSprite,
      breakable: !item
    });
  };

  Level.prototype.putSmallWave = function(x,y) {
    px = x*16;
    py = y*16;
    this.scenery[y - 1][x] = new Mario.Prop([px, py - 16], this.waveSprites[15]);
    this.scenery[y - 1][x + 1] = new Mario.Prop([px + 16, py - 16], this.waveSprites[16]);
    this.scenery[y - 1][x + 2] = new Mario.Prop([px + 32, py - 16], this.waveSprites[17]);
    this.scenery[y][x] = new Mario.Prop([px, py], this.waveSprites[18]);
    this.scenery[y][x + 1] = new Mario.Prop([px + 16, py], this.waveSprites[19]);
    this.scenery[y][x + 2] = new Mario.Prop([px + 32, py], this.waveSprites[20]);
  }

  Level.prototype.putPalm = function(x,y) {
    px = x*16;
    py = y*16;
    this.scenery[y - 3][x] = new Mario.Prop([px, py - 48], this.palmSprites[1]);
    this.scenery[y - 3][x + 1] = new Mario.Prop([px + 16, py - 48], this.palmSprites[2]);
    this.scenery[y - 3][x + 2] = new Mario.Prop([px + 32, py - 48], this.palmSprites[3]);
    this.scenery[y - 2][x] = new Mario.Prop([px, py - 32], this.palmSprites[4]);
    this.scenery[y - 2][x + 1] = new Mario.Prop([px + 16, py - 32], this.palmSprites[5]);
    this.scenery[y - 2][x + 2] = new Mario.Prop([px + 32, py - 32], this.palmSprites[6]);
    this.scenery[y - 1][x + 1] = new Mario.Prop([px + 16, py - 16], this.palmSprites[0]);
    this.scenery[y][x + 1] = new Mario.Prop([px + 16, py], this.palmSprites[0]);
  }

  Level.prototype.putBigWave = function(x,y) {
    px = x*16;
    py = y*16;
    this.scenery[y - 2][x] = new Mario.Prop([px, py - 32], this.waveSprites[0]);
    this.scenery[y - 2][x + 1] = new Mario.Prop([px + 16, py - 32], this.waveSprites[1]);
    this.scenery[y - 2][x + 2] = new Mario.Prop([px + 32, py - 32], this.waveSprites[2]);
    this.scenery[y - 2][x + 3] = new Mario.Prop([px + 48, py - 32], this.waveSprites[3]);
    this.scenery[y - 2][x + 4] = new Mario.Prop([px + 64, py - 32], this.waveSprites[4]);
    this.scenery[y - 1][x] = new Mario.Prop([px, py - 16], this.waveSprites[5]);
    this.scenery[y - 1][x + 1] = new Mario.Prop([px + 16, py - 16], this.waveSprites[6]);
    this.scenery[y - 1][x + 2] = new Mario.Prop([px + 32, py - 16], this.waveSprites[7]);
    this.scenery[y - 1][x + 3] = new Mario.Prop([px + 48, py - 16], this.waveSprites[8]);
    this.scenery[y - 1][x + 4] = new Mario.Prop([px + 64, py - 16], this.waveSprites[9]);
    this.scenery[y][x] = new Mario.Prop([px, py], this.waveSprites[10]);
    this.scenery[y][x + 1] = new Mario.Prop([px + 16, py], this.waveSprites[11]);
    this.scenery[y][x + 2] = new Mario.Prop([px + 32, py], this.waveSprites[12]);
    this.scenery[y][x + 3] = new Mario.Prop([px + 48, py], this.waveSprites[13]);
    this.scenery[y][x + 4] = new Mario.Prop([px + 64, py], this.waveSprites[14]);
  }

  Level.prototype.putBigHill = function(x, y) {
    px = x*16;
    py = y*16;
    this.scenery[y][x] = new Mario.Prop([px, py], this.hillSprites[0]);
    this.scenery[y][x+1] = new Mario.Prop([px+16, py], this.hillSprites[3]);
    this.scenery[y-1][x+1] = new Mario.Prop([px+16, py-16], this.hillSprites[0]);
    this.scenery[y][x+2] = new Mario.Prop([px+32, py], this.hillSprites[4]);
    this.scenery[y-1][x+2] = new Mario.Prop([px+32, py-16], this.hillSprites[3]);
    this.scenery[y-2][x+2] = new Mario.Prop([px+32, py-32], this.hillSprites[1]);
    this.scenery[y][x+3] = new Mario.Prop([px+48, py], this.hillSprites[5]);
    this.scenery[y-1][x+3] = new Mario.Prop([px+48, py-16], this.hillSprites[2]);
    this.scenery[y][x+4] = new Mario.Prop([px+64, py], this.hillSprites[2]);
  };

  Level.prototype.putBush = function(x, y) {
    this.scenery[y][x] = new Mario.Prop([x*16, y*16], this.bushSprite);
  };

  Level.prototype.putThreeBush = function(x,y) {
    px = x*16;
    py = y*16;
    this.scenery[y][x] = new Mario.Prop([px, py], this.bushSprites[0]);
    this.scenery[y][x+1] = new Mario.Prop([px+16, py], this.bushSprites[1]);
    this.scenery[y][x+2] = new Mario.Prop([px+32, py], this.bushSprites[1]);
    this.scenery[y][x+3] = new Mario.Prop([px+48, py], this.bushSprites[1]);
    this.scenery[y][x+4] = new Mario.Prop([px+64, py], this.bushSprites[2]);
  };

  Level.prototype.putTwoBush = function(x,y) {
    px = x*16;
    py = y*16;
    this.scenery[y][x] = new Mario.Prop([px, py], this.bushSprites[0]);
    this.scenery[y][x+1] = new Mario.Prop([px+16, py], this.bushSprites[1]);
    this.scenery[y][x+2] = new Mario.Prop([px+32, py], this.bushSprites[1]);
    this.scenery[y][x+3] = new Mario.Prop([px+48, py], this.bushSprites[2]);
  };

  Level.prototype.putTwoCastle = function(x,y) {
    px = x*16;
    py = y*16;
    for (let i = 0; i < 2; i++) {
      let xOffset = i * 2;
      let xPxOffset = i * 16;
      this.scenery[y - 1][x + xOffset] = new Mario.Prop([px + xPxOffset, py - 16], this.castleSprites[0]);
      this.scenery[y - 1][x + 1 + xOffset] = new Mario.Prop([px + 16 + xPxOffset, py - 16], this.castleSprites[1]);
      this.scenery[y - 1][x + 2 + xOffset] = new Mario.Prop([px + 32 + xPxOffset, py - 16], this.castleSprites[2]);
      this.scenery[y - 0][x + xOffset] = new Mario.Prop([px + xPxOffset, py], this.castleSprites[3]);
      this.scenery[y - 0][x + 1 + xOffset] = new Mario.Prop([px + 16 + xPxOffset, py], this.castleSprites[4]);
      this.scenery[y - 0][x + 2 + xOffset] = new Mario.Prop([px + 32 + xPxOffset, py], this.castleSprites[5]);
    }
  };

  Level.prototype.putCastle = function(x,y) {
    px = x*16;
    py = y*16;
    for (let i = 0; i < 1; i++) {
      let xOffset = i * 3;
      let xPxOffset = i * 32;
      this.scenery[y - 1][x + xOffset] = new Mario.Prop([px + xPxOffset, py - 16], this.castleSprites[0]);
      this.scenery[y - 1][x + 1 + xOffset] = new Mario.Prop([px + 16 + xPxOffset, py - 16], this.castleSprites[1]);
      this.scenery[y - 1][x + 2 + xOffset] = new Mario.Prop([px + 32 + xPxOffset, py - 16], this.castleSprites[2]);
      this.scenery[y - 0][x + xOffset] = new Mario.Prop([px + xPxOffset, py], this.castleSprites[3]);
      this.scenery[y - 0][x + 1 + xOffset] = new Mario.Prop([px + 16 + xPxOffset, py], this.castleSprites[4]);
      this.scenery[y - 0][x + 2 + xOffset] = new Mario.Prop([px + 32 + xPxOffset, py], this.castleSprites[5]);
    }
  };

  Level.prototype.putSmallHill = function(x, y) {
    px = x*16;
    py = y*16;
    this.scenery[y][x] = new Mario.Prop([px, py], this.hillSprites[0]);
    this.scenery[y][x+1] = new Mario.Prop([px+16, py], this.hillSprites[3]);
    this.scenery[y-1][x+1] = new Mario.Prop([px+16, py-16], this.hillSprites[1]);
    this.scenery[y][x+2] = new Mario.Prop([px+32, py], this.hillSprites[2]);
  };

  Level.prototype.putTwoCloud = function(x,y) {
    px = x*16;
    py = y*16;
    this.scenery[y][x] = new Mario.Prop([px, py], this.cloudSprites[0]);
    this.scenery[y][x+1] = new Mario.Prop([px+16, py], this.cloudSprites[1]);
    this.scenery[y][x+2] = new Mario.Prop([px+32, py], this.cloudSprites[1]);
    this.scenery[y][x+3] = new Mario.Prop([px+48, py], this.cloudSprites[2]);
  };

  Level.prototype.putThreeCloud = function(x,y) {
    px = x*16;
    py = y*16;
    this.scenery[y][x] = new Mario.Prop([px, py], this.cloudSprites[0]);
    this.scenery[y][x+1] = new Mario.Prop([px+16, py], this.cloudSprites[1]);
    this.scenery[y][x+2] = new Mario.Prop([px+32, py], this.cloudSprites[1]);
    this.scenery[y][x+3] = new Mario.Prop([px+48, py], this.cloudSprites[1]);
    this.scenery[y][x+4] = new Mario.Prop([px+64, py], this.cloudSprites[2]);
  };

  Level.prototype.putRealPipe = function(x, y, length, direction, destination) {
    px = x*16;
    py = y*16;
    this.pipes.push(new Mario.Pipe({
      pos: [px, py],
      length: length,
      direction: direction,
      destination: destination
    }));
  }

  Level.prototype.putFlagpole = function(x) {
    this.statics[12][x] = new Mario.Floor([16*x, 192], this.wallSprite);
    for (let i=3; i < 12; i++) {
      this.scenery[i][x] = new Mario.Prop([16*x, 16*i], this.flagpoleSprites[1])
    }
    this.scenery[2][x] = new Mario.Prop([16*x, 32], this.flagpoleSprites[0]);
    this.items.push(new Mario.Flag(16*x));
  }

  let oneone = Mario.oneone = function() {
    //The things that need to be passed in are basically just dependent on what
    //tileset we're in, so it makes more sense to just make one letiable for that, so
    //TODO: put as much of this in the Level object definition as possible.
    const ctx = canvas.getContext('2d');
    let grd = ctx.createLinearGradient(0, 0, 0, 240);
    grd.addColorStop(0, "rgb(61 220 245)");
    grd.addColorStop(1, "rgb(247 207 143)");

    // grd.addColorStop(1, "rgb(255 177 202");
    level = new Mario.Level({
      playerPos: [16,192],
      loader: Mario.oneone,
      background: grd,
      scrolling: true,
      invincibility: [144, 192, 240],
      exit: 204,
      floorSprite:  new Mario.Sprite('sprites/tiles.png', [0,0],[16,16],0),
      floorSprite2:  new Mario.Sprite('sprites/tiles.png', [224,400],[16,16],0),
      seaSprite: new Mario.Sprite('sprites/tiles.png', [48,416],[16,16],0),
      cloudSprite:  new Mario.Sprite('sprites/tiles.png', [0,320],[48,32],0),
      wallSprite: new Mario.Sprite('sprites/tiles.png', [0, 16],[16,16],0),
      brickSprite: new Mario.Sprite('sprites/tiles.png', [16, 0], [16,16], 0),
      brickBounceSprite: new Mario.Sprite('sprites/tiles.png',[32,0],[16,16],0),
      rubbleSprite: function () {
        return new Mario.Sprite('sprites/items.png', [64,0], [8,8], 3, [0,1])
      },
      ublockSprite: new Mario.Sprite('sprites/tiles.png', [48, 0], [16,16],0),
      superShroomSprite: new Mario.Sprite('sprites/items.png', [0,0], [16,16], 0),
      fireFlowerSprite: new Mario.Sprite('sprites/items.png', [0,32], [16,16], 20, [0,1,2,3]),
      starSprite: new Mario.Sprite('sprites/items.png', [0,48], [16,16], 20, [0,1,2,3]),
      pipeLEndSprite: new Mario.Sprite('sprites/tiles.png', [0, 128], [16,16], 0),
      pipeREndSprite: new Mario.Sprite('sprites/tiles.png', [16, 128], [16,16], 0),
      pipeLMidSprite: new Mario.Sprite('sprites/tiles.png', [0, 144], [16,16], 0),
      pipeRMidSprite: new Mario.Sprite('sprites/tiles.png', [16, 144], [16,16], 0),

      pipeUpMid: new Mario.Sprite('sprites/tiles.png', [0, 144], [32,16], 0),
      pipeSideMid: new Mario.Sprite('sprites/tiles.png', [48, 128], [16,32], 0),
      pipeLeft: new Mario.Sprite('sprites/tiles.png', [32, 128], [16,32], 0),
      pipeTop: new Mario.Sprite('sprites/tiles.png', [0, 128], [32,16], 0),
      qblockSprite: new Mario.Sprite('sprites/tiles.png', [384, 0], [16,16], 8, [0,0,0,0,1,2,1]),
      bounceSprite: new Mario.Sprite('sprites/tiles.png', [320,320],[16,16], 8, [0,0,0,0,1,2,1]),
      bcoinSprite: function() {
        return new Mario.Sprite('sprites/items.png', [0,112],[16,16], 20,[0,1,2,3]);
      },
      projectSprites: {
        bounce: new Mario.Sprite('sprites/tiles.png', [320,320],[16,16], 8, [0,0,0,0,1,2,1]),
        polls: new Mario.Sprite('sprites/tiles.png', [320,336],[16,16], 8, [0,0,0,0,1,2,1]),
        meetloaf: new Mario.Sprite('sprites/tiles.png', [320,352],[16,16], 8, [0,0,0,0,1,2,1]),
        warmupz: new Mario.Sprite('sprites/tiles.png', [320,368],[16,16], 8, [0,0,0,0,1,1,1]),
        cassette: new Mario.Sprite('sprites/tiles.png', [320,384],[16,16], 8, [0,0,0,0,1,2,1]),
        herenow: new Mario.Sprite('sprites/tiles.png', [320,400],[16,16], 8, [0,0,0,0,1,2,1]),
        pivot: new Mario.Sprite('sprites/tiles.png', [320,432],[16,16], 8, [0,0,0,0,1,1,1]),
        rollcall: new Mario.Sprite('sprites/tiles.png', [320,416],[16,16], 8, [0,0,0,0,1,1,1]),
        treehouse: new Mario.Sprite('sprites/tiles.png', [320,448],[16,16], 8, [0,0,0,0,1,2,1]),
        bookleague: new Mario.Sprite('sprites/tiles.png', [320,464],[16,16], 8, [0,0,0,0,1,2,1]),
      },
      cloudSprites:[
        new Mario.Sprite('sprites/tiles.png', [0,320],[16,32],0),
        new Mario.Sprite('sprites/tiles.png', [16,320],[16,32],0),
        new Mario.Sprite('sprites/tiles.png', [32,320],[16,32],0)
      ],
      hillSprites: [
        new Mario.Sprite('sprites/tiles.png', [128,128],[16,16],0),
        new Mario.Sprite('sprites/tiles.png', [144,128],[16,16],0),
        new Mario.Sprite('sprites/tiles.png', [160,128],[16,16],0),
        new Mario.Sprite('sprites/tiles.png', [128,144],[16,16],0),
        new Mario.Sprite('sprites/tiles.png', [144,144],[16,16],0),
        new Mario.Sprite('sprites/tiles.png', [160,144],[16,16],0)
      ],
      palmSprites: [
        new Mario.Sprite('sprites/tiles.png', [176,352],[16,16],0),
        new Mario.Sprite('sprites/tiles.png', [176,368],[16,16],0),
        new Mario.Sprite('sprites/tiles.png', [192,368],[16,16],0),
        new Mario.Sprite('sprites/tiles.png', [208,368],[16,16],0),
        new Mario.Sprite('sprites/tiles.png', [176,384],[16,16],0),
        new Mario.Sprite('sprites/tiles.png', [192,384],[16,16],0),
        new Mario.Sprite('sprites/tiles.png', [208,384],[16,16],0),
      ],
      castleSprites: [
        new Mario.Sprite('sprites/tiles.png', [224,368],[16,16],0),
        new Mario.Sprite('sprites/tiles.png', [240,368],[16,16],0),
        new Mario.Sprite('sprites/tiles.png', [256,368],[16,16],0),
        new Mario.Sprite('sprites/tiles.png', [224,384],[16,16],0),
        new Mario.Sprite('sprites/tiles.png', [240,384],[16,16],0),
        new Mario.Sprite('sprites/tiles.png', [256,384],[16,16],0),
      ],
      waveSprites: [
        new Mario.Sprite('sprites/tiles.png', [224,320],[16,16],0),
        new Mario.Sprite('sprites/tiles.png', [240,320],[16,16],0),
        new Mario.Sprite('sprites/tiles.png', [256,320],[16,16],0),
        new Mario.Sprite('sprites/tiles.png', [272,320],[16,16],0),
        new Mario.Sprite('sprites/tiles.png', [288,320],[16,16],0),
        new Mario.Sprite('sprites/tiles.png', [224,336],[16,16],0),
        new Mario.Sprite('sprites/tiles.png', [240,336],[16,16],0),
        new Mario.Sprite('sprites/tiles.png', [256,336],[16,16],0),
        new Mario.Sprite('sprites/tiles.png', [272,336],[16,16],0),
        new Mario.Sprite('sprites/tiles.png', [288,336],[16,16],0),
        new Mario.Sprite('sprites/tiles.png', [224,352],[16,16],0),
        new Mario.Sprite('sprites/tiles.png', [240,352],[16,16],0),
        new Mario.Sprite('sprites/tiles.png', [256,352],[16,16],0),
        new Mario.Sprite('sprites/tiles.png', [272,352],[16,16],0),
        new Mario.Sprite('sprites/tiles.png', [288,352],[16,16],0),
        // smalll wave
        new Mario.Sprite('sprites/tiles.png', [176,320],[16,16],0),
        new Mario.Sprite('sprites/tiles.png', [192,320],[16,16],0),
        new Mario.Sprite('sprites/tiles.png', [208,320],[16,16],0),
        new Mario.Sprite('sprites/tiles.png', [176,336],[16,16],0),
        new Mario.Sprite('sprites/tiles.png', [192,336],[16,16],0),
        new Mario.Sprite('sprites/tiles.png', [208,336],[16,16],0),
      ],
      bushSprite: new Mario.Sprite('sprites/tiles.png', [176, 144], [48, 16], 0),
      bushSprites: [
       new Mario.Sprite('sprites/tiles.png', [176,144], [16,16],0),
       new Mario.Sprite('sprites/tiles.png', [192,144], [16,16],0),
       new Mario.Sprite('sprites/tiles.png', [208,144], [16,16],0)],
     goombaSprite: function() {
       return new Mario.Sprite('sprites/enemy.png', [0, 16], [16,16], 3, [0,1]);
     },
     koopaSprite: function() {
       return new Mario.Sprite('sprites/enemy.png', [96,0], [16,32], 2, [0,1]);
     },
     flagPoleSprites: [
       new Mario.Sprite('sprites/tiles.png', [256, 128], [16,16], 0),
       new Mario.Sprite('sprites/tiles.png', [256, 144], [16,16], 0),
       new Mario.Sprite('sprites/items.png', [128, 32], [16,16], 0)
     ]
   });
    let ground = [[0,69],[71,86],[89,153],[155,212]];
    if (_initX != null) {
      player.initStroll = false;
      player.pos[0] = parseInt(_initX);
      _initX = level.playerPos[0];
    } else {
      player.pos[0] = level.playerPos[0]
    }
    player.pos[1] = level.playerPos[1];
    vX = 0;

    level.putSea();

    //build THE GROUND
    ground.forEach(function(loc) {
      level.putFloor(loc[0],loc[1]);
    });

    //build scenery
    let clouds = [[7,3],[19, 2],[56, 3],[67, 2],[87, 2],[103, 2],[152, 3],[163, 2],[200, 3]];
    clouds.forEach(function(cloud){
      level.putCloud(cloud[0],cloud[1]);
    });

    let twoClouds = [[36,2],[132,2],[180,2]];
    twoClouds.forEach(function(cloud){
      level.putTwoCloud(cloud[0],cloud[1]);
    });

    let threeClouds = [[27,3],[75,3],[123,3],[171,3]];
    threeClouds.forEach(function(cloud){
      level.putThreeCloud(cloud[0],cloud[1]);
    });

    let bHills = [48,96,144,192]
    bHills.forEach(function(hill) {
      level.putBigWave(hill, 12);
    });

    let sHills = [16,64,111,160];
    sHills.forEach(function(hill) {
      level.putSmallWave(hill, 12);
    });

    let bushes = [23,71,118,167];
    bushes.forEach(function(bush) {
      level.putPalm(bush, 12);
    });

    let twoBushes = [41,89,137];
    twoBushes.forEach(function(bush) {
      level.putCastle(bush, 12);
    });

    let threeBushes = [11,59,106];
    threeBushes.forEach(function(bush) {
      level.putTwoCastle(bush, 12);
    });

    //interactable terrain
    level.putQBlock(22, 5, new Mario.Bcoin([256, 144]));
    level.putBrick(15, 9, null);
    level.putProjectBlock(16, 9, null, "polls");
    level.putBrick(20, 9, null);
    level.putQBlock(19, 9, new Mario.Bcoin([304, 144]));
    level.putProjectBlock(21, 9, null, "bookleague");
    level.putBrick(22, 9, null);
    level.putBrick(7, 9, null);
    level.putProjectBlock(8, 9, null, "bounce");
    level.putBrick(9, 9, null)
    level.putPipe(28, 13, 2);
    level.putPipe(38, 13, 3);
    level.putProjectBlock(42, 9, null, "treehouse");
    level.putBrick(43, 9, null);
    level.putPipe(46, 13, 4);
    level.putBrick(55, 9, null);
    level.putProjectBlock(56, 9, null, "meetloaf");
    level.putBrick(57, 9, null);
    level.putBrick(77, 9, null);
    level.putProjectBlock(78, 9, null, "cassette");
    level.putBrick(79, 9, null);
    level.putBrick(80, 5, null);
    level.putBrick(81, 5, null);
    level.putBrick(82, 5, null);
    level.putBrick(83, 5, null);
    level.putBrick(84, 5, null);
    level.putBrick(85, 5, null);
    level.putBrick(86, 5, null);
    level.putBrick(87, 5, null);
    level.putBrick(91, 5, null);
    level.putBrick(92, 5, null);
    level.putBrick(93, 5, null);
    level.putQBlock(94, 5, new Mario.Bcoin([1504, 80]));
    level.putBrick(94, 9, null);
    level.putBrick(100, 9, new Mario.Star([1600, 144]));
    level.putBrick(101, 9, null);
    level.putProjectBlock(105, 9, null, "herenow");
    level.putQBlock(108, 9, new Mario.Bcoin([1728, 144]));
    level.putQBlock(108, 5, new Mario.Mushroom([1728, 80]));
    level.putProjectBlock(111, 9, null, "rollcall");
    level.putBrick(117, 9, null);
    level.putBrick(120, 5, null);
    level.putBrick(121, 5, null);
    level.putBrick(122, 5, null);
    level.putBrick(123, 5, null);
    level.putBrick(128, 5, null);
    level.putQBlock(129, 5, new Mario.Bcoin([2074, 80]));
    level.putProjectBlock(129, 9, null, "warmupz");
    level.putQBlock(130, 5, new Mario.Bcoin([2080, 80]));
    level.putBrick(130, 9, null);
    level.putBrick(131, 5, null);
    level.putWall(134, 13, 1);
    level.putWall(135, 13, 2);
    level.putWall(136, 13, 3);
    level.putWall(137, 13, 4);
    level.putWall(140, 13, 4);
    level.putWall(141, 13, 3);
    level.putWall(142, 13, 2);
    level.putWall(143, 13, 1);
    level.putWall(148, 13, 1);
    level.putWall(149, 13, 2);
    level.putWall(150, 13, 3);
    level.putWall(151, 13, 4);
    level.putWall(152, 13, 4);
    level.putWall(155, 13, 4);
    level.putWall(156, 13, 3);
    level.putWall(157, 13, 2);
    level.putWall(158, 13, 1);
    level.putPipe(163, 13, 2);
    level.putBrick(168, 9, null);
    level.putBrick(169, 9, null);
    level.putProjectBlock(170, 9, null, "pivot");
    level.putBrick(171, 9, null);
    level.putPipe(179, 13, 2);
    level.putWall(181, 13, 1);
    level.putWall(182, 13, 2);
    level.putWall(183, 13, 3);
    level.putWall(184, 13, 4);

    //and enemies
    // level.putGoomba(22, 12);
    level.putGoomba(40, 12);
    level.putGoomba(50, 12);
    // level.putGoomba(51, 12);
    level.putGoomba(82, 4);
    // level.putGoomba(84, 4);
    level.putGoomba(100, 12);
    // level.putGoomba(102, 12);
    level.putGoomba(114, 12);
    // level.putGoomba(115, 12);
    level.putGoomba(122, 12);
    // level.putGoomba(123, 12);
    // level.putGoomba(125, 12);
    level.putGoomba(126, 12);
    level.putGoomba(170, 12);
    // level.putGoomba(172, 12);
    level.putKoopa(35, 11);

    // music.underground.pause();
    // // music.overworld.currentTime = 0;
    // music.overworld.play();
  };

  let requestAnimFrame = (function(){
    return window.requestAnimationFrame       ||
      window.webkitRequestAnimationFrame ||
      window.mozRequestAnimationFrame    ||
      window.oRequestAnimationFrame      ||
      window.msRequestAnimationFrame     ||
      function(callback){
        window.setTimeout(callback, 1000 / 60);
      };
  })();

  //create the canvas
  let canvas = document.createElement("canvas");
  let characterCanvas = document.createElement("canvas");
  canvas.classList.add('gameCanvas');
  characterCanvas.classList.add('gameCanvas');
  let marioHolder = document.getElementById("marioHolder");
  let marioCharacterHolder = document.getElementById("marioCharacterHolder");
  let ctx = canvas.getContext('2d');
  let characterCtx = characterCanvas.getContext('2d');
  let updateables = [];
  let fireballs = [];
  let player = new Mario.Player([0,0]);
  canvas.addEventListener('click', function(e) {
    _onClick(player.pos);
  }, false);
  //we might have to get the size and calculate the scaling
  //but this method should let us make it however big.
  //Cool!
  //TODO: Automatically scale the game to work and look good on widescreen.
  //TODO: fiddling with scaled sprites looks BETTER, but not perfect. Hmm.
  // canvas.width = 762;.
  // canvas.height = 720;
  ctx.canvas.width  = Math.floor(marioHolder.clientWidth);
  ctx.canvas.height = 240;
  ctx.scale(1, 1);
  ctx.webkitImageSmoothingEnabled = false;
  ctx.mozImageSmoothingEnabled = false;
  ctx.imageSmoothingEnabled = false;
  marioHolder.appendChild(canvas);

  characterCtx.canvas.width  = Math.floor(marioCharacterHolder.clientWidth);
  characterCtx.canvas.height = 240;
  characterCtx.scale(1, 1);
  characterCtx.webkitImageSmoothingEnabled = false;
  characterCtx.mozImageSmoothingEnabled = false;
  characterCtx.imageSmoothingEnabled = false;
  marioCharacterHolder.appendChild(characterCanvas);

  //viewport
  let vX = 0,
      vY = 0,
      vWidth = 256,
      maxGround = 212,
      canvasScale = 2.5,
      vHeight = 480;

  let paused = false;

  //load our images
  resources.load([
    'sprites/player.png',
    'sprites/enemy.png',
    'sprites/tiles.png',
    'sprites/playerl.png',
    'sprites/items.png',
    'sprites/enemyr.png',
  ]);

  resources.onReady(init);
  let level;
  let sounds;
  let music;

  //initialize
  let lastTime;
  function init() {
    music = {
      overworld: new Audio('sounds/aboveground_bgm.ogg'),
      underground: new Audio('sounds/underground_bgm.ogg'),
      clear: new Audio('sounds/stage_clear.wav'),
      death: new Audio('sounds/mariodie.wav')
    };
    sounds = {
      smallJump: new Audio('sounds/jump-small.wav'),
      bigJump: new Audio('sounds/jump-super.wav'),
      breakBlock: new Audio('sounds/breakblock.wav'),
      bump: new Audio('sounds/bump.wav'),
      coin: new Audio('sounds/coin.wav'),
      fireball: new Audio('sounds/fireball.wav'),
      flagpole: new Audio('sounds/flagpole.wav'),
      kick: new Audio('sounds/kick.wav'),
      pipe: new Audio('sounds/pipe.wav'),
      itemAppear: new Audio('sounds/itemAppear.wav'),
      powerup: new Audio('sounds/powerup.wav'),
      stomp: new Audio('sounds/stomp.wav')
    };
    Mario.oneone();
    lastTime = Date.now();
    main();
  }

  let gameTime = 0;
  let animationLoop = null;

  //set up the game loop
  function main() {
    let now = Date.now();
    let dt = (now - lastTime) / 1000.0;

    if (!paused) {
      update(dt);
      render();
    }
    lastTime = now;
    animationLoop = requestAnimFrame(main);
  }

  function update(dt) {
    gameTime += dt;

    handleInput(dt);
    updateEntities(dt, gameTime);

    checkCollisions();
  }

  function handleInput(dt) {
    if (player.piping || player.dying || player.noInput) return; //don't accept input
    if (input.isDown('RUN')){
      player.run();
    } else {
      player.noRun();
    }
    if (input.isDown('JUMP')) {
      player.jump();
    } else {
      //we need this to handle the timing for how long you hold it
      player.noJump();
    }

    if (input.isDown('DOWN')) {
      player.crouch();
    } else {
      player.noCrouch();
    }

    if (input.isDown('LEFT')) { // 'd' or left arrow
      player.moveLeft();
    }
    else if (input.isDown('RIGHT')) { // 'k' or right arrow
      player.moveRight();
    } else {
      player.noWalk();
    }
  }

  //update all the moving stuff
  function updateEntities(dt, gameTime) {
    player.update(dt, vX);
    updateables.forEach (function(ent) {
      ent.update(dt, gameTime);
    });

    //This should stop the jump when he switches sides on the flag.
    // console.log("cw" + canvas.width);
    if (player.exiting) {
      if (player.pos[0] > vX + 96)
        vX = player.pos[0] - 96
    }
    else if (
      (player.pos[0] + (canvas.width / canvasScale) - 80) >= (16 * maxGround)
    ) {
      vX = (16 * maxGround) - (canvas.width / canvasScale);
    }
    else if (level.scrolling && player.pos[0] > 80) {
      vX = player.pos[0] - 80;
    }

    if (player.powering.length !== 0 || player.dying) { return; }
    level.items.forEach (function(ent) {
      ent.update(dt);
    });

    level.enemies.forEach (function(ent) {
      ent.update(dt, vX);
    });

    fireballs.forEach(function(fireball) {
      fireball.update(dt);
    });
    level.pipes.forEach (function(pipe) {
      pipe.update(dt);
    });
  }

  //scan for collisions
  function checkCollisions() {
    if (player.powering.length !== 0 || player.dying) { return; }
    player.checkCollisions();

    //Apparently for each will just skip indices where things were deleted.
    level.items.forEach(function(item) {
      item.checkCollisions();
    });
    level.enemies.forEach (function(ent) {
      ent.checkCollisions();
    });
    fireballs.forEach(function(fireball){
      fireball.checkCollisions();
    });
    level.pipes.forEach (function(pipe) {
      pipe.checkCollisions();
    });
  }

  //draw the game!
  function render() {
    updateables = [];
    characterCtx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.fillStyle = level.background;
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    //scenery gets drawn first to get layering right.
    for(let i = 0; i < 15; i++) {
      for (let j = Math.floor(vX / 16) - 1; j < Math.floor(vX / 16) + (marioHolder.clientWidth / 16); j++){
        if (level.scenery[i][j]) {
          renderEntity(level.scenery[i][j]);
        }
      }
    }

    // level.intro.render(ctx, 0, 0, vX, vY);

    //then items
    level.items.forEach (function (item) {
      renderEntity(item);
    });

    level.enemies.forEach (function(enemy) {
      renderEntity(enemy);
    });



    fireballs.forEach(function(fireball) {
      renderEntity(fireball);
    })

    //then we draw every static object.
    for(let i = 0; i < 15; i++) {
      for (let j = Math.floor(vX / 16) - 1; j < Math.floor(vX / 16) + (marioHolder.clientWidth / 16); j++){
        if (level.statics[i][j]) {
          renderEntity(level.statics[i][j]);
        }
        if (level.blocks[i][j]) {
          renderEntity(level.blocks[i][j]);
          updateables.push(level.blocks[i][j]);
        }
      }
    }

    //then the player
    if (player.invincibility % 2 === 0) {
      renderEntity(player, characterCtx);
    }

    //Mario goes INTO pipes, so naturally they go after.
    level.pipes.forEach (function(pipe) {
      renderEntity(pipe);
    });
  }

  function renderEntity(entity, customCtx) {
    entity.render(customCtx || ctx, vX, vY);
  }

  function resume() {
    paused = false;
  }

  function clearAnimationLoop() {
    window.cancelAnimationFrame(animationLoop);
    clearlets();
  }

  function clearlets() {
    Mario = null;
    level = null;
  }


  return {
    setButtonPress,
    resume,
    removeListeners,
    clearAnimationLoop,
  }
};

export default game;
