import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styles from "./GameArrow.module.css"

const GameArrow = ({ direction, active }) => {
  return (
    <div className={`${styles.arrowWrapper} ${active && styles.active} ${styles[`GameArrow${direction}`]}`}>
      <div className={`${styles.arrowTop} ${styles.arrowLayer}`}></div>
      <div className={`${styles.arrowMiddle} ${styles.arrowLayer}`}></div>
      <div className={`${styles.arrowBottom} ${styles.arrowLayer}`}></div>
    </div>
  );
}

export default GameArrow;
