import React, { useEffect, useState } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import ProjectWrapper from "../components/project/ProjectWrapper"
import MarioGame from "../components/game/MarioGame"
import globalStyles from "./global.module.css"
import styles from "./index.module.css"
import PixelBorder from "../components/accents/PixelBorder"
import Img from "gatsby-image"

const IndexPage = () => {
  const [scrollX, setScrollX] = useState(0)
  const [forceShowLogo, setForceShowLogo] = useState(false)
  const query = useStaticQuery(graphql`
    query {
      bounce: file(relativePath: { eq: "projects/bounce/bounce-home.jpg" }) {
        ...homeImage
      }
      meetloaf: file(relativePath: { eq: "projects/meetloaf/header.png" }) {
        ...homeImage
      }
      polls: file(relativePath: { eq: "projects/polls/header.png" }) {
        ...homeImage
      }
      cassette: file(relativePath: { eq: "projects/cassette/header.png" }) {
        ...homeImage
      }
      rollcall: file(relativePath: { eq: "projects/rollcall/header.png" }) {
        ...homeImage
      }
      pivot: file(relativePath: { eq: "projects/pivot/header.png" }) {
        ...homeImage
      }
      herenow: file(relativePath: { eq: "projects/herenow/header.png" }) {
        ...homeImage
      }
      warmupz: file(relativePath: { eq: "projects/warmupz/header.png" }) {
        ...homeImage
      }
      treehouse: file(relativePath: { eq: "projects/treehouse/header.png" }) {
        ...homeImage
      }
      bookleague: file(relativePath: { eq: "projects/bookleague/header.png" }) {
        ...homeImage
      }
      wavy: file(relativePath: { eq: "wavy.png" }) {
        ...homeImage
      }
      wavy2: file(relativePath: { eq: "wavy2.png" }) {
        ...homeImage
      }
      wedping: file(relativePath: { eq: "projects/wedping/header.png" }) {
        ...homeImage
      }
    }
  `)

  const createProjectWrapper = (
    { name, id, backgroundColor, description, fluidImg, publicImg },
    inGame
  ) => {
    return (
      <ProjectWrapper
        name={name}
        id={id}
        backgroundColor={backgroundColor}
        darkBorder="rgb(107 132 255)"
        lightBorder="rgb(187 212 255)"
        description={description}
        fluidImg={fluidImg}
        publicImg={publicImg}
        inGame={inGame}
      />
    )
  }

  const bounceInfo = {
    id: "bounce",
    name: "Bounce",
    description: "We pick the place, you bring your face",
    backgroundColor: "rgb(240, 243, 255)",
    fluidImg: query.bounce.childImageSharp.fluid,
    publicImg: query.bounce.publicURL,
  }

  const meetloafInfo = {
    id: "meetloaf",
    name: "Meetloaf",
    description: "The Dream Team Machine",
    backgroundColor: "rgb(255, 243, 249)",
    fluidImg: query.meetloaf.childImageSharp.fluid,
    publicImg: query.meetloaf.publicURL,
  }

  const pollsInfo = {
    id: "polls",
    name: "PocketPolls",
    description: "Check politcal polls from your phone",
    backgroundColor: "rgb(242, 242, 242)",
    fluidImg: query.polls.childImageSharp.fluid,
    publicImg: query.polls.publicURL,
  }

  const cassetteInfo = {
    id: "cassette",
    name: "Cassette",
    description: "The first ever audio Q&A app",
    backgroundColor: "rgb(234, 234, 234)",
    fluidImg: query.cassette.childImageSharp.fluid,
    publicImg: query.cassette.publicURL,
  }

  const rollcallInfo = {
    id: "rollcall",
    name: "Rollcall",
    description: "The fun way to stay connected",
    backgroundColor: "rgb(238, 255, 253)",
    fluidImg: query.rollcall.childImageSharp.fluid,
    publicImg: query.rollcall.publicURL,
  }

  const pivotInfo = {
    id: "pivot",
    name: "The Pivot",
    description: "Where pro women athletes connect with brands",
    backgroundColor: "rgb(244, 255, 225)",
    fluidImg: query.pivot.childImageSharp.fluid,
    publicImg: query.pivot.publicURL,
  }

  const herenowInfo = {
    id: "herenow",
    name: "HereNow",
    description: "Automating an experience platform",
    backgroundColor: "rgb(255, 247, 235)",
    fluidImg: query.herenow.childImageSharp.fluid,
    publicImg: query.herenow.publicURL,
  }

  const warmupzInfo = {
    id: "warmupz",
    name: "Warmupz",
    description: "Meeting warmups your team will actually enjoy",
    backgroundColor: "rgb(255, 250, 222)",
    fluidImg: query.warmupz.childImageSharp.fluid,
    publicImg: query.warmupz.publicURL,
  }

  const treehouseInfo = {
    id: "treehouse",
    name: "Treehouse",
    description: "A new venue for virtual shows",
    backgroundColor: "#e7ffd6",
    fluidImg: query.treehouse.childImageSharp.fluid,
    publicImg: query.treehouse.publicURL,
  }

  const bookleagueInfo = {
    id: "bookleague",
    name: "Book League",
    description: "The first social reading tracker",
    backgroundColor: "#67DED0",
    fluidImg: query.bookleague.childImageSharp.fluid,
    publicImg: query.bookleague.publicURL,
  }

  const wedpingInfo = {
    id: "wedping",
    name: "Wedping",
    description: "The easiest way to text your wedding guests",
    backgroundColor: "#FFD4D4",
    fluidImg: query.wedping.childImageSharp.fluid,
    publicImg: query.wedping.publicURL,
  }

  const bounce = createProjectWrapper(bounceInfo)
  const bounceGame = createProjectWrapper(bounceInfo, true)

  const meetloaf = createProjectWrapper(meetloafInfo)
  const meetloafGame = createProjectWrapper(meetloafInfo, true)

  const treehouse = createProjectWrapper(treehouseInfo)
  const treehouseGame = createProjectWrapper(treehouseInfo, true)

  const cassette = createProjectWrapper(cassetteInfo)
  const cassetteGame = createProjectWrapper(cassetteInfo, true)

  const polls = createProjectWrapper(pollsInfo)
  const pollsGame = createProjectWrapper(pollsInfo, true)

  const herenow = createProjectWrapper(herenowInfo)
  const herenowGame = createProjectWrapper(herenowInfo, true)

  const pivot = createProjectWrapper(pivotInfo)
  const pivotGame = createProjectWrapper(pivotInfo, true)

  const rollcall = createProjectWrapper(rollcallInfo)
  const rollcallGame = createProjectWrapper(rollcallInfo, true)

  const warmupz = createProjectWrapper(warmupzInfo)
  const warmupzGame = createProjectWrapper(warmupzInfo, true)

  const bookleague = createProjectWrapper(bookleagueInfo)
  const bookleagueGame = createProjectWrapper(bookleagueInfo, true)

  const wedping = createProjectWrapper(wedpingInfo)

  return (
    <Layout
      excludeHeader
      fixedBgColor
      bodyBgColor={"var(--ice)"}
      forceShowLogo={forceShowLogo}
      headerBackground={"var(--blue)"}
    >
      <SEO
        title={`Long Weekend ~ A digital product workshop`}
        description={
          "Long Weekend is a digital product workshop. We design and develop our own mobile and web apps and sometimes work with others."
        }
      />

      <div className={styles.marioWrap}>
        <MarioGame
          projects={{
            bounce: { component: bounceGame, position: 8 },
            polls: { component: pollsGame, position: 16 },
            treehouse: { component: treehouseGame, position: 43 },
            meetloaf: { component: meetloafGame, position: 56 },
            cassette: { component: cassetteGame, position: 78 },
            herenow: { component: herenowGame, position: 105 },
            rollcall: { component: rollcallGame, position: 111 },
            pivot: { component: pivotGame, position: 170 },
            warmupz: { component: warmupzGame, position: 129 },
            bookleague: { component: bookleagueGame, position: 22 },
          }}
          showFullMenu={() => {
            setForceShowLogo(true)
          }}
          hideFullMenu={() => {
            setForceShowLogo(false)
          }}
        />
      </div>
      <div
        className={styles.waves1}
        style={{
          backgroundImage: `url('${query.wavy2.publicURL}')`,
          backgroundSize: "contain",
          width: "100%",
        }}
      ></div>

      <div className={styles.whatWeDo}>
        <div className={styles.ourWork}>
          <h1 style={{ whiteSpace: "pre-wrap" }}>
            Our work here{"\n"}is{" "}
            <span style={{ position: "relative" }}>
              <span className={styles.strike}></span>done
            </span>{" "}
            <span className={styles.fun}>fun</span>
          </h1>
          <div>
            <p>
              Long Weekend is a digital product workshop. We design and develop
              our own mobile and web apps and sometimes work with others.
            </p>
            <p>
              We've brought discovery to podcasting, serendipity back to dating,
              helped teams work better together, and made tracking political
              polls as easy as checking the weather.
            </p>
            <p style={{ marginBottom: 0 }}>
              Check out what we're working on and drop us a line if you'd like
              to work together.
            </p>
          </div>
        </div>
      </div>
      <div id="products" className={styles.projectSep}></div>
      <div className={styles.projectListWrapper}>
        <ul className={styles.projectList}>
          <li className={`${styles.projectListItem}`}>{bounce}</li>
          <li
            className={`${styles.projectListItem} ${styles.projectListItemRight} ${styles.projectListItemLiftTop}`}
          >
            {polls}
          </li>
          <li
            className={`${styles.projectListItem} ${styles.projectListItemLiftTop}`}
          >
            {bookleague}
          </li>
          <li
            className={`${styles.projectListItem} ${styles.projectListItemRight} ${styles.projectListItemLiftTop}`}
          >
            {treehouse}
          </li>
          <li
            className={`${styles.projectListItem} ${styles.projectListItemLiftTop}`}
          >
            {meetloaf}
          </li>
          <li
            className={`${styles.projectListItem} ${styles.projectListItemRight} ${styles.projectListItemLiftTop}`}
          >
            {cassette}
          </li>
          <li
            className={`${styles.projectListItem} ${styles.projectListItemLiftTop}`}
          >
            {pivot}
          </li>
          <li
            className={`${styles.projectListItem} ${styles.projectListItemRight} ${styles.projectListItemLiftTop}`}
          >
            {herenow}
          </li>
          <li
            className={`${styles.projectListItem} ${styles.projectListItemLiftTop}`}
          >
            {rollcall}
          </li>
          <li
            className={`${styles.projectListItem} ${styles.projectListItemRight} ${styles.projectListItemLiftTop}`}
          >
            {warmupz}
          </li>
          <li
            className={`${styles.projectListItem} ${styles.projectListItemLiftTop}`}
          >
            {wedping}
          </li>
        </ul>
      </div>
    </Layout>
  )
}

export const headerImage = graphql`
  fragment homeImage on File {
    publicURL
    childImageSharp {
      fluid(maxWidth: 1200) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export default IndexPage
